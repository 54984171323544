import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  Switch,
  Grid,
  AccordionDetails,
  Typography,
  OutlinedInput,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import TopBarProgress from "react-topbar-progress-indicator";
import MultiSearchBar from "../../components/MultiSearchBar/MultiSearchBar";
import {
  getBowComparisonOptions,
  getSelectTagOptions,
} from "../../utils/utils";
import { calcDfAtDl } from "../../utils/math";
import type { TorsionPoint, Sample } from "../../types/apiTypes";
import type { OptionType, RegressionCurves } from "../../types/index";
import MultiBowGraph from "../../components/MultiBowGraph/MultiBowGraph";
import ComparativeTable from "../../components/ComparativeTable/ComparativeTable";
import { getSample, getTorsionForSample } from "../../utils/apiCalls";
/* eslint-disable no-unused-vars */
import MultiBowTorsionGraph from "../../components/MultiBowTorsionGraph/MultiBowTorsionGraph";

interface SampleWithTitle {
  sample: Sample;
  title: string;
  torsionData?: TorsionPoint[];
}

interface TorsionalStabilityData {
  torsionData: TorsionPoint[];
  label: string;
  normalizeBy?: number;
}

const Compare = () => {
  const [allSampleOptions, setAllSampleOptions] = useState<OptionType[]>([]);
  const [filteredBowOptions, setFilteredBowOptions] = useState<OptionType[]>(
    []
  );
  const [filteredTagOptions, setFilteredTagOptions] = useState<OptionType[]>(
    []
  );
  const [fetchedSamples, setFetchedSamples] = useState<SampleWithTitle[]>([]);
  const [tagSearch, setTagSearch] = useState(false);
  const [, setSelectedTags] = useState([]);
  const [chosenCurves, setChosenCurves] = useState<RegressionCurves[]>([]);
  const [loading, setLoading] = useState(false);
  const [torsionalStabilityData, setTorsionalStabilityData] = useState<
    TorsionalStabilityData[]
  >([]);

  const [dl, setDl] = useState<number>(28);
  const [dws, setDws] = useState<{ [key: string]: number }>({});
  useEffect(() => {
    const fetcher = async () => {
      setLoading(true);
      const allSamples = (await getBowComparisonOptions()).filter(sample => {
        return !sample.label.includes("BowTech");
      });
      const fetchedTags = await getSelectTagOptions();
      setAllSampleOptions(allSamples);
      setFilteredBowOptions(allSamples);
      setFilteredTagOptions(fetchedTags);
      setLoading(false);
    };
    fetcher();
  }, []);

  useEffect(() => {
    const newDws = fetchedSamples.reduce<{ [key: string]: number }>(
      (acc, sample) => {
        acc[sample.title] = calcDfAtDl(sample.sample.coeffs, dl);
        return acc;
      },
      {}
    );
    setDws(newDws);
  }, [dl, fetchedSamples]);

  const handleSelectBow = async (
    selectedOptions: readonly OptionType[] | null
  ) => {
    if (selectedOptions !== null && selectedOptions.length > 0) {
      const selectedSampleIds = selectedOptions.map(option => {
        return { sampleId: parseFloat(option.value), title: option.label };
      });

      // Add bow case
      if (selectedSampleIds.length > chosenCurves.length) {
        const chosenSampleIds = chosenCurves.map(curve => {
          return curve.sampleId;
        });
        const newSamples = selectedSampleIds.filter(sample => {
          return !chosenSampleIds.includes(sample.sampleId);
        });

        setLoading(true);
        const fetchedNewSamples = await Promise.all(
          newSamples.map(async sample => {
            const newSample = await getSample(sample.sampleId);
            const torsionPoint = await getTorsionForSample(sample.sampleId);
            return {
              sample: newSample as Sample,
              title: sample.title,
              torsionData: torsionPoint,
            };
          })
        );
        setFetchedSamples([
          ...fetchedSamples,
          ...fetchedNewSamples,
        ] as SampleWithTitle[]);

        const newCurves = fetchedNewSamples.map(sample => {
          return {
            regressionCurve: sample.sample.regressionCurve,
            regressionDerivative: sample.sample.regressionDerivativeValues,
            regressionCoeffs: sample.sample.coeffs,
            title: sample.title,
            sampleId: sample.sample.sampleId,
          } as RegressionCurves;
        });

        const newTorsionData = fetchedNewSamples.map(sample => {
          const candidate = newCurves.find(curveCandidate => {
            return curveCandidate.title === sample.title;
          }) as RegressionCurves;
          const nominalPoundage = candidate.regressionCurve.find(
            (point: number[]) => {
              return point[0] === 27;
            }
          );
          return {
            torsionData: sample.torsionData,
            label: sample.title,
            nominalPoundage: nominalPoundage ? nominalPoundage[1] : undefined,
          } as TorsionalStabilityData;
        });

        setChosenCurves([...chosenCurves, ...newCurves]);
        setTorsionalStabilityData([
          ...torsionalStabilityData,
          ...newTorsionData,
        ]);
        setLoading(false);
      } else {
        // Remove bow case

        const filteredCurves = chosenCurves.filter(curve => {
          return selectedSampleIds
            .map(x => {
              return x.sampleId;
            })
            .includes(curve.sampleId);
        });

        const filteredCurveTitles = selectedOptions.map(curve => {
          return curve.label;
        });
        const filteredTorsionalData = torsionalStabilityData.filter(
          torsionalSample => {
            return (
              filteredCurveTitles.find(title => {
                return title === torsionalSample.label;
              }) !== undefined
            );
          }
        );
        const selectedOptionLabels = selectedOptions.map(option => {
          return option.label;
        });
        const filteredFetchedSamples = fetchedSamples.filter(sample => {
          return selectedOptionLabels.indexOf(sample.title) !== -1;
        });
        setFetchedSamples(filteredFetchedSamples);
        setChosenCurves(filteredCurves);
        setTorsionalStabilityData(filteredTorsionalData);
        setLoading(false);
      }
    }
    if (selectedOptions !== null && selectedOptions.length === 0) {
      setChosenCurves([]);
      setTorsionalStabilityData([]);
    }
  };

  const handleResetSearches = () => {
    setFilteredBowOptions([...allSampleOptions]);
    setSelectedTags([]);
  };

  const handleSelectTag = (selectedOptions: readonly OptionType[] | null) => {
    const filterBowOptions = () => {
      return allSampleOptions.filter(bow => {
        const tagIds = bow.extraProps.tagIds || [];
        return selectedOptions?.every(option => {
          return tagIds.includes(parseFloat(option.value));
        });
      });
    };

    if (selectedOptions !== null) {
      const newOptions = filterBowOptions();
      setFilteredBowOptions(newOptions);
    }
  };

  return (
    <div>
      {loading && <TopBarProgress />}
      <div>
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item> Search by bow model </Grid>
          <Grid item>
            <Switch
              checked={tagSearch !== false}
              onChange={() => {
                handleResetSearches();
                return setTagSearch(!tagSearch);
              }}
            />
          </Grid>
          <Grid item> Search by tags </Grid>
        </Grid>
      </div>
      {tagSearch === true && (
        <div>
          <MultiSearchBar
            name={
              filteredTagOptions.length > 0
                ? "Select a tag"
                : "No tags available"
            }
            onChange={handleSelectTag}
            options={filteredTagOptions}
          />
        </div>
      )}
      <MultiSearchBar
        name={
          filteredBowOptions.length > 0 ? "Select a bow" : "No bows available"
        }
        onChange={handleSelectBow}
        options={filteredBowOptions}
      />
      <div>
        {chosenCurves.length > 0 && (
          <div>
            <ComparativeTable samples={fetchedSamples} />
            <MultiBowGraph
              bowData={chosenCurves.map(regressionCurve => {
                return {
                  curve: regressionCurve.regressionCurve,
                  regressionCoeffs: regressionCurve.regressionCoeffs,
                  title: regressionCurve.title,
                  sampleId: regressionCurve.sampleId,
                };
              })}
              showEnergy
              yLabel="Draw weight (lbs)"
            />
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="h6">Draw Weights</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Typography>Draw Weights At </Typography>
                  </Grid>
                  <Grid item>
                    <OutlinedInput
                      inputMode="numeric"
                      type="number"
                      id="calc-dfc"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value === "") {
                          setDl(0);
                          return;
                        }
                        const userDl = parseFloat(e.target.value);
                        if (
                          !Number.isNaN(userDl) &&
                          userDl >= 0 &&
                          userDl < 100
                        ) {
                          setDl(userDl);
                        }
                      }}
                      size="small"
                      endAdornment={
                        <InputAdornment position="end"> inches</InputAdornment>
                      }
                      value={dl}
                      margin="dense"
                      sx={{
                        width: "20ch",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography> from the belly</Typography>
                  </Grid>
                </Grid>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Bow Title</TableCell>
                        <TableCell align="right">Poundage (lbs)</TableCell>
                        <TableCell align="right">
                          Draw length from back (inches)
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fetchedSamples.map(sample => {
                        return (
                          <TableRow>
                            <TableCell>{sample.title}</TableCell>
                            <TableCell align="right">
                              {dws[sample.title]
                                ? dws[sample.title].toPrecision(4)
                                : null}
                              lbs
                            </TableCell>
                            <TableCell align="right">
                              {sample.sample.gripDepth !== undefined &&
                              sample.sample.gripDepth > 1
                                ? (
                                    dl -
                                    sample.sample.gripDepth / 25.4
                                  ).toPrecision(4)
                                : "No grip depth"}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
            <MultiBowGraph
              bowData={chosenCurves.map(regressionCurve => {
                return {
                  curve: regressionCurve.regressionDerivative,
                  title: regressionCurve.title,
                  sampleId: regressionCurve.sampleId,
                };
              })}
              yLabel="Change in draw weight (lbpi)"
            />
          </div>
        )}
        {torsionalStabilityData.length !== 0 ? (
          <MultiBowTorsionGraph torsionData={torsionalStabilityData} />
        ) : null}
      </div>
    </div>
  );
};

export default Compare;

/*
            {torsionalStabilityData.length !== 0 ? (
              <MultiBowTorsionGraph torsionData={torsionalStabilityData} />
            ) : null}
            */
