import React, { useState } from "react";
import { Button } from "@mui/material";
import ModifySample from "../../components/ModifySample/ModifySample";
import ModifyBow from "../../components/ModifyBow/ModifyBow";
import AddBow from "../../components/AddBow/AddBow";
import AddTag from "../../components/AddTag/AddTag";
import UploadSample from "../../components/UploadSample/UploadSample";
import AddShafts from "../../components/AddShafts/AddShafts";
import AddCollars from "../../components/AddCollars/AddCollars";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import AddFpsData from "../../components/AddFpsData/AddFpsData";
import { clearCache } from "../../utils/apiCalls";

const Editor = () => {
  const [view, setView] = useState("upload");
  return (
    <div>
      <div>
        <div>
          <div>
            <label htmlFor="addBow">
              <input
                type="radio"
                id="addBow"
                checked={view === "addBow"}
                onChange={() => {
                  return setView("addBow");
                }}
              />
              Add Bow
            </label>
          </div>
          <div>
            <label htmlFor="modifyBow">
              <input
                type="radio"
                id="modifyBow"
                checked={view === "modifyBow"}
                onChange={() => {
                  return setView("modifyBow");
                }}
              />
              Modify Bow
            </label>
          </div>
          <div>
            <label htmlFor="addTags">
              <input
                type="radio"
                id="modifyTags"
                checked={view === "addTags"}
                onChange={() => {
                  return setView("addTags");
                }}
              />
              Add Tags
            </label>
          </div>
          <label htmlFor="uploadData">
            <input
              type="radio"
              id="upload"
              checked={view === "upload"}
              onChange={() => {
                return setView("upload");
              }}
            />
            Upload Sample
          </label>
        </div>
        <div>
          <label htmlFor="modifyData">
            <input
              type="radio"
              id="modifySample"
              checked={view === "modifySample"}
              onChange={() => {
                return setView("modifySample");
              }}
            />
            Modify Sample
          </label>
        </div>
        <div>
          <label htmlFor="addShafts">
            <input
              type="radio"
              id="addShafts"
              checked={view === "addShafts"}
              onChange={() => {
                return setView("addShafts");
              }}
            />
            Add shafts
          </label>
        </div>

        <div>
          <label htmlFor="addShafts">
            <input
              type="radio"
              id="addCollars"
              checked={view === "addCollars"}
              onChange={() => {
                return setView("addCollars");
              }}
            />
            Add Collars
          </label>
        </div>

        <div>
          <label htmlFor="addImages">
            <input
              type="radio"
              id="addImages"
              checked={view === "addImages"}
              onChange={() => {
                return setView("addImages");
              }}
            />
            Add Images
          </label>
        </div>
        <div>
          <label htmlFor="addFps">
            <input
              type="radio"
              id="addFps"
              checked={view === "addFps"}
              onChange={() => {
                return setView("addFps");
              }}
            />
            Add FPS data
          </label>
        </div>

        <div>
          <label htmlFor="clearCache">
            <input
              type="radio"
              id="clearCache"
              checked={view === "clearCache"}
              onChange={() => {
                return setView("clearCache");
              }}
            />
            Clear cache
          </label>
        </div>
      </div>
      <div>
        {view === "upload" && <UploadSample />}
        {view === "modifySample" && (
          <div>
            <ModifySample />
          </div>
        )}
        {view === "modifyBow" && (
          <div>
            <ModifyBow />
          </div>
        )}
        {view === "addBow" && (
          <div>
            <AddBow />
          </div>
        )}

        {view === "addTags" && (
          <div>
            <AddTag />
          </div>
        )}

        {view === "addShafts" && (
          <div>
            <AddShafts />
          </div>
        )}

        {view === "addCollars" && (
          <div>
            <AddCollars />
          </div>
        )}

        {view === "addImages" && (
          <div>
            <ImageUpload />
          </div>
        )}
        {view === "addFps" && (
          <div>
            <AddFpsData />
          </div>
        )}
        {view === "clearCache" && (
          <div>
            <Button
              onClick={() => {
                clearCache();
              }}
            >
              {" "}
              Clear Cache{" "}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Editor;
