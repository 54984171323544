/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import { FormControl, Autocomplete, TextField } from "@mui/material";
import Fuse from "fuse.js";
import "./MultiSearchBar.css";
import type { OptionType } from "../../types";

interface MultiSearchBarProps {
  name: string;
  onChange: (selectedOption: readonly OptionType[] | null) => void;
  options: OptionType[];
}

const MultiSearchBar: React.FC<MultiSearchBarProps> = ({
  name,
  onChange,
  options,
}) => {
  const [visibleOptions, setVisibleOptions] = useState<OptionType[]>(options);

  // Initialize Fuse with options
  const fuse = new Fuse(options, {
    keys: ["label"],
    threshold: 0.4,
    ignoreLocation: true,
  });

  // Filter function using Fuse.js
  const filterOptions = (e: any, val: any) => {
    return val.inputValue.length > 0
      ? fuse.search(val.inputValue).map(result => {
          return result.item;
        })
      : e;
  };

  // Handle selection change
  const handleOnChange = (event: any, selectedOptions: OptionType[]) => {
    onChange(selectedOptions);
  };

  useEffect(() => {
    setVisibleOptions(options);
  }, [options]);

  return (
    <div className="multiSearchBarContainer">
      <FormControl>
        <Autocomplete
          multiple
          disablePortal
          options={visibleOptions}
          onChange={handleOnChange}
          filterOptions={filterOptions}
          renderInput={params => {
            return <TextField {...params} label={name} />;
          }}
          getOptionLabel={option => {
            return option.label;
          }}
        />
      </FormControl>
    </div>
  );
};

export default MultiSearchBar;
