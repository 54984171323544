import React from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import type { SampleData, BowTag } from "../../types";
import type { Sample, Tag } from "../../types/apiTypes";
import TagDisplay from "../TagDisplay/TagDisplay";
import BowData from "../BowData/BowData";

interface BowDataContainerProps {
  data: Sample;
  tags: Tag[];
  direction: string;
}

const BowDataContainer: React.FC<BowDataContainerProps> = ({
  data,
  tags,
  direction,
}) => {
  return (
    <Stack direction={direction === "row" ? "row" : "column"} spacing={2}>
      <Accordion
        sx={{
          border: 1,
          borderColor: theme => {
            return theme.palette.action.disabled;
          },
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography> Tags </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TagDisplay tags={tags} />
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{
          border: 1,
          borderColor: theme => {
            return theme.palette.action.disabled;
          },
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography> Bow Data </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BowData sample={data} />
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default BowDataContainer;
