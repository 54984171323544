/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */

import React, { useState } from "react";

import Editor from "../Editor/Editor";
import { login } from "../../utils/apiCalls";

const LoginEditor = () => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setLoggedIn] = useState(false);

  const postToLogin = async () => {
    const response = await login(username, password);
    if ("token" in response.data && response.data.token.length > 0) {
      setLoggedIn("token" in response.data);
      document.cookie = `Bearer ${response.data.token}; SameSite=None; Secure`;
    }
  };

  return (
    <div>
      {!isLoggedIn && (
        <div className="login-wrapper">
          <h1>Please Log In</h1>
          <form>
            <label>
              <p>Username</p>
              <input
                type="text"
                onChange={e => {
                  return setUserName(e.target.value);
                }}
              />
            </label>
            <label>
              <p>Password</p>
              <input
                type="password"
                onChange={e => {
                  return setPassword(e.target.value);
                }}
              />
            </label>
            <div>
              <button
                type="submit"
                onClick={async e => {
                  e.preventDefault();
                  postToLogin();
                }}
              >
                Login
              </button>
            </div>
          </form>
        </div>
      )}
      {isLoggedIn && <Editor />}
    </div>
  );
};

export default LoginEditor;
