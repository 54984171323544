import React, { useState, useEffect } from "react";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCollars, getShafts } from "../../utils/apiCalls";
import ShaftTable from "../../components/ShaftTable/ShaftTable";
import CollarTable from "../../components/CollarTable/CollarTable";
import type { Shaft, Collar } from "../../types/apiTypes";

const ArrowData = () => {
  const [shafts, setShafts] = useState<Shaft[]>([]);
  const [collars, setCollars] = useState<Collar[]>([]);

  useEffect(() => {
    const fetcher = async () => {
      const fetchedShafts = await getShafts();
      const fetchedCollars = await getCollars();
      setShafts(fetchedShafts);
      setCollars(fetchedCollars);
    };
    fetcher();
  }, []);

  return (
    <div>
      {shafts.length === 0 && <TopBarProgress />}
      {shafts.length > 0 && <ShaftTable data={shafts} />}
      {collars.length > 0 && <CollarTable data={collars} />}
    </div>
  );
};

export default ArrowData;
