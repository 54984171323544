/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Chart, registerables } from "chart.js";

import type { Point } from "chart.js";
import {
  FormControlLabel,
  Switch,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import { MathJax } from "better-react-mathjax";
import useTheme from "@mui/material/styles/useTheme.js";
import useMediaQuery from "@mui/material/useMediaQuery";
import regression from "regression";
// @ts-ignore
import ChartjsPluginWatermark from "chartjs-plugin-watermark";
import chartTrendline from "chartjs-plugin-trendline";
import { Line } from "react-chartjs-2";
import { hashToColor } from "../../utils/utils";
import type { TorsionPoint } from "../../types/apiTypes";

import { MIN_SCREEN_WIDTH, WATERMARK_SETTINGS } from "../../constants";

Chart.register(...registerables);
Chart.register(ChartjsPluginWatermark);
Chart.register(chartTrendline);

interface TorsionalStabilityData {
  torsionData: TorsionPoint[];
  label: string;
  nominalPoundage?: number;
}

interface TorsionalStabilityDataWithRegression extends TorsionalStabilityData {
  gradient: number;
  intercept: number;
}

interface MultiBowTorsionGraphProps {
  torsionData: TorsionalStabilityData[];
}

const MultiBowTorsionGraph: React.FC<MultiBowTorsionGraphProps> = ({
  torsionData,
}) => {
  const usableData = torsionData
    .filter(dataset => {
      return dataset.torsionData.length > 0;
    })
    .map(dataset => {
      const regressionValues = regression.linear(
        dataset.torsionData.map(point => {
          return [point.mass, point.deflection];
        }),
        { order: 2, precision: 10 }
      );
      return {
        ...dataset,
        gradient: regressionValues.equation[0],
        intercept: regressionValues.equation[1],
      } as TorsionalStabilityDataWithRegression;
    });

  const screenIsMinWidth = useMediaQuery(`(min-width:${MIN_SCREEN_WIDTH}px)`);
  const theme = useTheme();
  Chart.defaults.borderColor = theme.palette.grey["400"];

  const [normalize, setNormalize] = useState(false);

  const data = {
    datasets: usableData.map(
      (torsionalSample: TorsionalStabilityDataWithRegression) => {
        const backgroundColor = hashToColor(torsionalSample.label);
        return {
          label: torsionalSample.label,
          pointStyle: "cross",
          borderColor: backgroundColor,
          backgroundColor,
          radius: 8,
          data: torsionalSample.torsionData.map((point: TorsionPoint) => {
            if (normalize && torsionalSample.nominalPoundage !== undefined) {
              return {
                x: (point.mass / (torsionalSample.nominalPoundage * 454)) * 100,
                y: point.deflection,
              };
            }
            return { x: point.mass, y: point.deflection } as Point;
          }),
          showLine: false,
          tension: 0.5,
          trendlineLinear: {
            lineStyle: "dotted",
            width: 2,
            projection: true,
          },
        };
      }
    ),
  };

  const options = {
    scales: {
      x: {
        display: true,
        border: { width: 1.5, color: theme.palette.text.primary },
        type: "linear" as const,
        title: {
          text: normalize
            ? "Mass in % of draw weight at 28 inches"
            : "Mass in g",
          display: true,
        },
        ticks: {
          callback: (value: any) => {
            return normalize ? `${value}%` : value;
          },
        },
      },
      y: {
        display: true,
        border: { width: 1.5, color: theme.palette.text.primary },
        position: "left" as const,
        title: {
          text: "Deflection (inches)",
          display: true,
        },
      },
    },
    plugins: {
      autocolors: false,
      tooltip: {
        callbacks: {
          title(context: any) {
            return context[0].dataset.label;
          },
          label(context: any) {
            const returnVal = [
              context.label,
              `${context.formattedValue}inches`,
            ];
            if (normalize) {
              returnVal[0] = `${context.label}%`;
            } else {
              returnVal[0] = `${context.label}g`;
            }
            return `(${returnVal.join(", ")})`;
          },
        },
      },
      title: {
        display: screenIsMinWidth,
        text: "Torsional Stabiilty",
      },
      responsive: true,
    },
    watermark: WATERMARK_SETTINGS,
  };

  return (
    <Stack spacing={2}>
      <Line data={data} options={options} />
      <FormControlLabel
        control={
          <Switch
            checked={normalize}
            onClick={() => {
              setNormalize(!normalize);
            }}
          />
        }
        label="Normalize data"
      />
      <TableContainer component={Paper}>
        <Table
          sx={{
            "& .MuiTableRow-root:hover": {
              backgroundColor: "inherit", // Or 'transparent' or whatever color you'd like
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ border: 0, fontSize: 20 }}>
                Torsional Stability Data
              </TableCell>
            </TableRow>
            <TableRow hover={false}>
              <TableCell>Bow Name</TableCell>
              <TableCell>Slope</TableCell>
              <TableCell>Intercept</TableCell>
              <TableCell>Poundage @ 28 inches</TableCell>
              <TableCell>Normalized slope</TableCell>
            </TableRow>
          </TableHead>
          {usableData.map(dataset => {
            return (
              <TableRow hover={false}>
                <TableCell>{dataset.label}</TableCell>
                <TableCell>
                  <MathJax>\({dataset.gradient.toExponential(5)}\)</MathJax>
                </TableCell>
                <TableCell>
                  <MathJax>\({dataset.intercept.toExponential(5)}\)</MathJax>
                </TableCell>
                <TableCell>{dataset.nominalPoundage}</TableCell>
                <TableCell>
                  {dataset.nominalPoundage ? (
                    <MathJax>
                      \(
                      {(
                        dataset.gradient / dataset.nominalPoundage
                      ).toExponential(5)}
                      \)
                    </MathJax>
                  ) : (
                    0
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default MultiBowTorsionGraph;
