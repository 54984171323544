/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { FormControl, Autocomplete, TextField } from "@mui/material";
import Fuse from "fuse.js";

import "./SearchBar.css";
import type { OptionType } from "../../types";

interface SearchBarProps {
  name: string;
  // eslint-disable-next-line
  onChange: (selectedOption: OptionType | null) => void;
  options: OptionType[];
  forcedValue?: OptionType | null;
}

const SearchBar: React.FC<SearchBarProps> = ({
  name,
  onChange,
  options,
  forcedValue: value,
}) => {
  const fuse = new Fuse(options, {
    keys: ["label"],
    threshold: 0.4,
    ignoreLocation: true,
  });

  const filterOptions = (e: any, val: any) => {
    if (val.inputValue !== "") {
      const fuseResults = fuse.search(val.inputValue);
      return fuseResults.map(el => {
        return el.item;
      });
    }
    return options;
  };

  return (
    <div className="searchBarContainer">
      <FormControl>
        <Autocomplete
          disablePortal
          sx={{
            mt: 2,
          }}
          options={options}
          onChange={(e: any, v: any) => {
            return onChange(v);
          }}
          filterOptions={filterOptions}
          renderInput={params => {
            return <TextField {...params} label={name} />;
          }}
          value={value || null}
        />
      </FormControl>
    </div>
  );
};

SearchBar.defaultProps = {
  forcedValue: null,
};

export default SearchBar;
