/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URL from "../../constants";

interface SendToDbButtonProps {
  data: any;
  endpoint?: any;
  successMessage: string;
  form?: boolean;
  f?: Function;
}

const SendToDbButton: React.FC<SendToDbButtonProps> = ({
  data,
  endpoint,
  successMessage,
  form,
  f,
}) => {
  const [responseStatus, setResponseStatus] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const handleClick = async () => {
    setShowToast(false);
    try {
      if (form === false) {
        let response;
        if (f === undefined) {
          response = await axios.post(
            `${API_URL}/${endpoint}`,
            JSON.stringify(data),
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: document.cookie,
              },
            }
          );
        } else {
          response = await f(data);
        }
        setResponseStatus(response.status);
        setShowToast(true);
      } else {
        const response = await axios.post(`${API_URL}/${endpoint}`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: document.cookie,
          },
        });

        setResponseStatus(response.status);
        setShowToast(true);
      }
    } catch (error) {
      toast.error(`Failed to submit with error ${error}`);
    }
  };

  useEffect(() => {
    if (showToast) {
      if (responseStatus >= 200 && responseStatus < 300) {
        const toastPackage = {
          successMessage,
          options: {
            position: toast.POSITION.TOP_RIGHT,
          },
        };

        toast.success(toastPackage.successMessage, toastPackage.options);
      } else if (responseStatus >= 400) {
        toast.error(`Failed to submit with error ${responseStatus}`);
      }
    }
  }, [showToast]);

  return (
    <div>
      <ToastContainer />
      <button type="submit" onClick={handleClick}>
        Submit Data
      </button>
    </div>
  );
};

SendToDbButton.defaultProps = {
  form: false,
  endpoint: "none",
  f: async () => {
    return 0;
  },
};

export default SendToDbButton;
