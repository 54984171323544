/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */
export const calcDfAtDl = (coeffs: number[], dl: number) => {
  return (
    (coeffs[2] / coeffs[0]) * Math.exp(coeffs[0] * dl) +
    (coeffs[3] / coeffs[1]) * Math.exp(coeffs[1] * dl) +
    coeffs[4]
  );
};

export const calcEnergyAtPoint = (
  coeffs: number[],
  start: number,
  end: number
) => {
  const integral = (x: number) => {
    let sum = 0;
    const step = 0.001; // Adjust this value for desired precision
    for (let i = start; i < x; i += step) {
      sum += calcDfAtDl(coeffs, i) * step;
    }
    return sum;
  };
  const energy = integral(end) - integral(start);
  return energy * 0.11298;
};
