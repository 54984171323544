import React from "react";
import { Chip, Tooltip } from "@mui/material";
import { hashToColor } from "../../utils/utils";

interface TagProps {
  tag: string;
  descriptor?: string;
}

const Tag: React.FC<TagProps> = ({ tag, descriptor }) => {
  return (
    <Tooltip title={descriptor} arrow>
      <Chip
        label={tag}
        sx={{
          backgroundColor: hashToColor(
            descriptor !== undefined ? descriptor : tag
          ),
          color: theme => {
            return theme.palette.text.primary;
          },
          m: 0.25,
        }}
        variant="outlined"
      />
    </Tooltip>
  );
};

Tag.defaultProps = {
  descriptor: "",
};

export default Tag;
