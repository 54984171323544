import greyLogo from "./assests/images/asiaticbow_grey.png";

const API_URL = process.env.REACT_APP_BE_API_LINK
  ? process.env.REACT_APP_BE_API_LINK
  : "http://localhost:3001";

export const MIN_SCREEN_WIDTH = 700; // Min screen width in pixels to display things horizontally
export const WATERMARK_SETTINGS = {
  image: greyLogo,
  opacity: 0.3,
  alignX: "middle",
  alignY: "middle",
  height: "30%",
  width: "30%",
  alignToChartArea: true,
};

export default API_URL;
