import React from "react";
import Tag from "../Tag/Tag";
import type { Tag as TagType } from "../../types/apiTypes";
import "./TagDisplay.css";

interface TagDisplayProps {
  tags: TagType[];
}

const TagDisplay: React.FC<TagDisplayProps> = ({ tags }) => {
  return (
    <div>
      {tags.length > 0 &&
        tags.map(tag => {
          return (
            <Tag
              tag={tag.tagName}
              descriptor={tag.tagDescription}
              key={tag.tagDescription}
            />
          );
        })}
      {tags.length === 0 && <Tag tag="No tags!" />}
    </div>
  );
};

export default TagDisplay;
