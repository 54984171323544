/* eslint-disable no-unused-vars */
// <reference path="path/types.d.ts" />
import React, { useEffect, useState } from "react";
import { MathJaxContext } from "better-react-mathjax";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { IconButton } from "@mui/material";
import { Brightness4, Brightness7 } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline/CssBaseline";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./App.css";
import Header from "./components/Header/Header";
import Home from "./pages/Home/Home";
import LoginEditor from "./pages/LoginEditor/LoginEditor";
import Compare from "./pages/Compare/Compare";
import ArrowData from "./pages/ArrowData/ArrowData";
import Contributing from "./pages/Contributing/Contributing";
import Glossary from "./pages/Glossary/Glossary";
import Footer from "./components/Footer/Footer";
import Faq from "./pages/Faq/Faq";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#433281",
      // light: "#7873ab",
      // dark: "#260d54",
      // contrastText: "#e7e6f0",
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltipPlacementTop: {
          "&.MuiTooltip-tooltipArrow": {
            marginBottom: "10px !important",
          },
          marginBottom: "4px !important",
        },
        tooltipPlacementRight: {
          "&.MuiTooltip-tooltipArrow": {
            marginLeft: "12px !important",
          },
          marginLeft: "4px !important",
        },
        tooltipPlacementBottom: {
          "&.MuiTooltip-tooltipArrow": {
            marginTop: "4px !important",
          },
          marginTop: "0px !important",
        },
        tooltipPlacementLeft: {
          "&.MuiTooltip-tooltipArrow": {
            marginRight: "12px !important",
          },
          marginRight: "4px !important",
        },
      },
    },
  },
});

const lightTheme = createTheme({
  palette: {
    primary: {
      main: "#d67e81",
    },
    mode: "light",
    background: {
      paper: "#f6eee3",
      default: "#f6eee3",
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltipPlacementTop: {
          "&.MuiTooltip-tooltipArrow": {
            marginBottom: "10px !important",
          },
          marginBottom: "4px !important",
        },
        tooltipPlacementRight: {
          "&.MuiTooltip-tooltipArrow": {
            marginLeft: "12px !important",
          },
          marginLeft: "4px !important",
        },
        tooltipPlacementBottom: {
          "&.MuiTooltip-tooltipArrow": {
            marginTop: "4px !important",
          },
          marginTop: "0px !important",
        },
        tooltipPlacementLeft: {
          "&.MuiTooltip-tooltipArrow": {
            marginRight: "12px !important",
          },
          marginRight: "4px !important",
        },
      },
    },
  },
});

const App = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)", {
    noSsr: true,
  });
  const [darkMode, setDarkMode] = React.useState(prefersDarkMode);
  const [theme, setTheme] = useState(
    darkMode === true ? darkTheme : lightTheme
  );

  const toggleColourMode = () => {
    setDarkMode(!darkMode);
  };

  useEffect(() => {
    if (darkMode === true) {
      setTheme(createTheme(darkTheme));
    } else {
      setTheme(createTheme(lightTheme));
    }
  }, [darkMode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <MathJaxContext>
        <div id="app">
          <IconButton sx={{ ml: 1 }} onClick={toggleColourMode}>
            {theme.palette.mode === "light" ? <Brightness7 /> : <Brightness4 />}
          </IconButton>
          <div className="app">
            <BrowserRouter>
              <Header />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/editor" element={<LoginEditor />} />
                <Route path="/compare" element={<Compare />} />
                <Route path="/arrows" element={<ArrowData />} />
                <Route path="/contributing" element={<Contributing />} />
                <Route path="/Glossary" element={<Glossary />} />
                <Route path="/faq" element={<Faq />} />
              </Routes>
              <Footer />
            </BrowserRouter>
          </div>
        </div>
      </MathJaxContext>
    </ThemeProvider>
  );
};

export default App;
