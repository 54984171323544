import React, { useState } from "react";
import SendToDbButton from "../SendToDbButton/SendToDbButton";
import { addBowType } from "../../utils/apiCalls";

const AddBow = () => {
  const [manufacturer, setManufacturer] = useState("");
  const [modelName, setModelName] = useState("");
  const [bowLink, setBowLink] = useState("");

  const sendToDb = (data: {
    manufacturer: string;
    modelName: string;
    bowLink: string;
  }) => {
    return addBowType(data.manufacturer, data.modelName, data.bowLink);
  };

  return (
    <div>
      <div>
        <table>
          <thead>
            <tr>
              <th> Property </th>
              <th> Value </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>manufacturer</th>
              <th>
                <input
                  type="text"
                  id="fname"
                  name="fname"
                  value={manufacturer}
                  onChange={e => {
                    return setManufacturer(e.target.value);
                  }}
                />
              </th>
            </tr>
            <tr>
              <th>model name</th>
              <th>
                <input
                  type="text"
                  id="fname"
                  name="fname"
                  value={modelName}
                  onChange={e => {
                    return setModelName(e.target.value);
                  }}
                />
              </th>
            </tr>
            <tr>
              <th>bow link</th>
              <th>
                <input
                  type="text"
                  id="fname"
                  name="fname"
                  value={bowLink}
                  onChange={e => {
                    return setBowLink(e.target.value);
                  }}
                />
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <SendToDbButton
          data={{ manufacturer, modelName, bowLink }}
          f={sendToDb}
          endpoint="api/bows/new"
          successMessage="Successfully added new bow"
        />
      </div>
    </div>
  );
};

export default AddBow;
