import React, { useState, useEffect } from "react";
import SearchBar from "../SearchBar/SearchBar";
import {
  getSelectBowOptions,
  getSelectTagOptions,
  updateBow,
} from "../../utils/utils";
import type { OptionType } from "../../types";
import "./ModifyBow.css";
import SendToDbButton from "../SendToDbButton/SendToDbButton";
import { getTagsForBow } from "../../utils/apiCalls";
import type { BowTypeTag, Tag } from "../../types/apiTypes";

const ModifyBow = () => {
  const [options, setOptions] = useState<OptionType[]>([]);
  const [tagOptions, setTagOptions] = useState<OptionType[]>([]);

  const [selectedTag, setSelectedTag] = useState<OptionType | null>(null);
  const [selectedBow, setSelectedBow] = useState<OptionType | null>(null);
  const [manufacturer, setManufacturer] = useState("");
  const [modelName, setModelName] = useState("");
  const [selectedBowTags, setBowTags] = useState<Tag[]>([]);
  const [bowLink, setBowLink] = useState("");
  useEffect(() => {
    const fetcher = async () => {
      const fetchedBows = await getSelectBowOptions();
      const fetchedTags = await getSelectTagOptions();
      setOptions(fetchedBows);
      setTagOptions(fetchedTags);
    };
    fetcher();
  }, []);

  useEffect(() => {
    const fetcher = async () => {
      if (selectedBow !== null) {
        const tags = await getTagsForBow(parseFloat(selectedBow?.value));
        const tagIds = tags.map((tag: BowTypeTag) => {
          return tag.tagId;
        });
        setBowTags(
          tagOptions
            .filter((tag: OptionType) => {
              return tagIds.indexOf(parseFloat(tag.value)) !== -1;
            })
            .map((tag: OptionType) => {
              return tag.extraProps as Tag;
            })
        );
      }
    };
    fetcher();
  }, [selectedBow]);

  const handleSelectChange = (selectedOption: OptionType | null) => {
    setSelectedBow(selectedOption);
    setBowLink(selectedOption?.extraProps.bow_link);
    setModelName(selectedOption?.extraProps.model_name);
    setManufacturer(selectedOption?.extraProps.manufacturer);
  };

  const handleTagSelectChange = (selectedTagOption: OptionType | null) => {
    if (selectedTagOption !== null) {
      setSelectedTag(selectedTagOption);
    }
  };

  const addTagToBowModel = () => {
    if (selectedTag !== null) {
      setBowTags([...selectedBowTags, selectedTag.extraProps]);
    }
  };

  const sendToDb = (data: {
    bowTypeId: number;
    manufacturer: string;
    modelName: string;
    bowLink: string;
    tags: number[];
  }) => {
    return updateBow(data);
  };

  return (
    <div>
      <SearchBar
        name={options.length > 0 ? "Select a bow" : "Loading bow options"}
        onChange={handleSelectChange}
        options={options}
        forcedValue={selectedBow}
      />
      <div>
        {selectedBow?.extraProps !== undefined && (
          <div>
            <table className="editingTable">
              <thead>
                <tr>
                  <th> Property </th>
                  <th> Value </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>manufacturer</th>
                  <th>
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      value={manufacturer}
                      onChange={e => {
                        return setManufacturer(e.target.value);
                      }}
                    />
                  </th>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <th>model name</th>
                  <th>
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      value={modelName}
                      onChange={e => {
                        return setModelName(e.target.value);
                      }}
                    />
                  </th>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <th>bow link</th>
                  <th>
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      value={bowLink}
                      onChange={e => {
                        return setBowLink(e.target.value);
                      }}
                    />
                  </th>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <th>tags</th>
                  <th>
                    <input
                      type="text"
                      name="tags"
                      className="tagInput"
                      value={selectedBowTags
                        .map(tag => {
                          return tag.tagName;
                        })
                        .join(", ")}
                      onChange={() => {}}
                    />
                  </th>
                </tr>
              </tbody>
            </table>
            <SearchBar
              name={
                tagOptions.length > 0 ? "Select a tag" : "Loading tag options"
              }
              onChange={handleTagSelectChange}
              options={tagOptions}
              forcedValue={selectedTag}
            />
            <button type="submit" onClick={addTagToBowModel}>
              Add Tag{" "}
            </button>

            <div>
              <SendToDbButton
                data={{
                  bowTypeId: parseFloat(selectedBow.value),
                  manufacturer,
                  modelName,
                  bowLink,
                  tags: selectedBowTags.map((tag: Tag) => {
                    return tag.tagId;
                  }),
                }}
                f={sendToDb}
                endpoint="api/bows/modify"
                successMessage="Successfully modified bow"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModifyBow;
