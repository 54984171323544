const renderTooltipVals = [
  {
    val: "unstrungLength",
    description: "Unstrung contour length of the bow, measured in cm",
  },
  {
    val: "strungLength",
    description: "Strung length of the bow from tip to tip, measured in cm",
  },
  {
    val: "maxDraw",
    description: "Max draw length of the bow, measured in inches",
  },
  {
    val: "braceHeight",
    description:
      "Brace height, measured from bowstring to deepest part of grip, in cm",
  },
  {
    val: "stockStringLengthMax",
    description:
      "Maximum length of the stock string, from end of loop to end of loop, in cm",
  },
  {
    val: "stockStringLengthMin",
    description:
      "Minimum length of the stock string, from beginning of loop to beginning of loop, in cm",
  },
  { val: "bowMass", description: "Bow mass, measured in grams" },
  { val: "arrowPassWidth", description: "Arrow pass width, measured in mm" },
  {
    val: "gripDepth",
    description:
      "Depth of the grip; maximal distance from the belly to the back on the grip. Measured in mm",
  },
  {
    val: "gripLength",
    description:
      "Length of the grip; maximal distance from top to bottom. Measured in mm",
  },
  {
    val: "gripWidth",
    description:
      "Width of the grip; maximal distance from side to side. Measured in mm",
  },
  {
    val: "minLimbThickness",
    description:
      "Minimum limb thickness along the bending section of the limb, from belly to back. Measured in mm",
  },
  {
    val: "maxLimbThickness",
    description:
      "Max limb thickness along the bending section of the limb, from belly to back. Measured in mm",
  },
  {
    val: "minLimbWidth",
    description:
      "Minimum limb width along the bending section of the limb, from belly to back. Measured in mm",
  },
  {
    val: "maxLimbWidth",
    description:
      "Max limb width along the bending section of the limb, from belly to back. Measured in mm",
  },
  {
    val: "siyahEffectiveTopAngle",
    description:
      "Effective angle between the end of the top bending limb and the nock. Measured in degrees.",
  },
  {
    val: "siyahEffectiveBottomAngle",
    description:
      "Effective angle between the end of the bottom bending limb and the nock. Measured in degrees.",
  },
  {
    val: "siyahEffectiveTopLength",
    description:
      "Effective length between the end of the top bending limb and the nock. Measured in cm.",
  },
  {
    val: "siyahEffectiveBottomLength",
    description:
      "Effective length between the end of the bottom bending limb and the nock. Measured in cm.",
  },
  {
    val: "minBoxDepth",
    description:
      "Depth of the shallowest box that the bow could fit into. Measured in cm.",
  },
  {
    val: "minBoxLength",
    description:
      "Length of the shallowest box that the bow could fit into. Measured in cm.",
  },
  {
    val: "minBoxWidth",
    description:
      "Width of the shallowest box that the bow could fit into. Measured in cm.",
  },
  { val: "asymLengthBottom", description: "Bottom limb length, in cm" },
  { val: "asymLengthTop", description: "Top limb length, in cm" },
  { val: "dfData", description: "Draw force data, in (inch, pound) pairs" },
  {
    val: "manufactureDate",
    description:
      "Year and month when the bow was manufactured to our best knowledge",
  },
  {
    val: "measurementDate",
    description: "Year and month when the bow was measured",
  },
  {
    val: "contributorContactInfo",
    description: "Contributor contact information",
  },
  { val: "contributorContactType", description: "Contributor contact type" },
];

export default renderTooltipVals;
