/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useTheme from "@mui/material/styles/useTheme.js";
import shaftDataColumns from "./Columns";
import "./ShaftTable.css";
import type { Shaft } from "../../types/apiTypes";

interface ShaftTableProps {
  data: Shaft[];
}

const ShaftTable: React.FC<ShaftTableProps> = ({ data }) => {
  const theme = useTheme();
  const [shaftData, setShaftData] = useState<Shaft[]>(data);
  const [globalSelected, setSelectedRows] = useState<Shaft[]>([]);

  const handleRowSelected = ({ selectedRows }: { selectedRows: Shaft[] }) => {
    setSelectedRows(selectedRows);
  };

  useEffect(() => {
    const updatedShaftData = [
      ...globalSelected,
      ...shaftData.filter(row => {
        return !globalSelected.includes(row);
      }),
    ];
    setShaftData(updatedShaftData);
  }, [globalSelected]);

  const customSort = (rows: any[], selector: any, direction: string) => {
    return rows.sort((rowA, rowB) => {
      // use the selector function to resolve your field names by passing the sort comparitors
      const aField = selector(rowA) === "unknown" ? 0 : selector(rowA);
      const bField = selector(rowB) === "unknown" ? 0 : selector(rowB);

      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }

      return direction === "desc" ? comparison * -1 : comparison;
    });
  };
  // Set a unique keyfield for each row
  return (
    <div className="custom-datatable-container">
      <Accordion sx={{ paddingBottom: "5px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <Typography color={theme.palette.primary.contrastText}>
            Shaft Data
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DataTable
            title="Arrow Data"
            columns={shaftDataColumns}
            data={shaftData}
            selectableRows
            theme={theme.palette.mode}
            keyField="shaftId"
            sortFunction={customSort}
            onSelectedRowsChange={handleRowSelected}
            fixedHeader
            pagination
            paginationPerPage={25}
            paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
            striped
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ShaftTable;
