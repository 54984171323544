/* eslint-disable react/no-unescaped-entities */
import {
  Typography,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  List,
  ListItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Faq = () => {
  return (
    <Stack sx={{ maxWidth: "70%" }}>
      <Typography variant="h3">Frequently Asked Questions</Typography>

      <Typography variant="body1">
        For more information, check out our youtube channel{" "}
        <Link href="https://www.youtube.com/@asiaticbowdb">@asiaticbowdb</Link>,
        or our blog <Link href="https://asiaticbow.cafe/">asiaticbow.cafe</Link>
      </Typography>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: theme => {
              return theme.palette.primary.main;
            },
          }}
        >
          <Typography variant="h4">Disclaimers</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <Typography variant="h5">
              1. A draw force curve does not directly reflect the quality of the
              bow.
            </Typography>
            <Typography variant="body1">
              Consider it like the scent of a perfume. The draw force curve
              (dfc) is akin to the description on the perfume box, indicating
              the scent. While it can't determine the quality of the perfume, it
              does assist you in identifying if it's the scent you prefer.
            </Typography>
            <Typography variant="h5">
              2. The measurements in this database are unprocessed and follow
              the standard on the contributing page
            </Typography>
            <Typography variant="body1">
              If you plan to contrast these measurements with others, ensure the
              measurement methodologies align with the ones on the contribution
              page
            </Typography>
            <Typography variant="h5">
              3. Measurements are intended to supplement subjective opinions,
              not to be the foundation for them.
            </Typography>
            <Typography variant="body1">
              While some individuals may want to debate which bow is superior
              based on the metrics on this site, remember, this database does
              not conclusively determine the worth of a bow. What makes a bow
              great or not is ultimately a personal judgment. This database
              serves as a catalog of bows that my team and I have managed to
              assess.
            </Typography>
            <Typography variant="h5">
              4. Variations in units do occur.
            </Typography>
            <Typography variant="body1">
              We don't have control over the units provided for measurement. If
              you identify issues with our measurements, the causes could be one
              or more of the following:
              <List>
                <ListItem>a) Irregular manufacturing</ListItem>
                <ListItem>
                  b) Instances of malfunction or breakage, which may not be
                  immediately apparent
                </ListItem>
                <ListItem>
                  c) Error in measurement (We're human, and we sometimes make
                  mistakes)
                </ListItem>
                <ListItem>
                  d) Typographical errors in the data (Again, we're human)
                </ListItem>
              </List>
            </Typography>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: theme => {
              return theme.palette.primary.main;
            },
          }}
        >
          <Typography variant="h4"> Who are we? </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Nerds. Bow nerds. We&apos;re a group of Asiatic archers who wanted
            hard, numeric data to inform our purchasing decisions for Asiatic
            archery equipment. At the time of conception of this site, the only
            sources of information available on asiaitc bows was either via
            Armin Hirmer or via manufacturers. Not to rag on Armin, but I wanted
            more numbers than what his videos could give me, and more than what
            manufacturers could give me. So I began making this site, eventually
            drawing in a few like minded bow nerds.{" "}
          </Typography>
          <br />
          <Typography>
            <Typography variant="h5">
              Fiona <Typography>Data Shark</Typography>
            </Typography>
            aka{" "}
            <Link href="https://www.instagram.com/sinophilic_toxophilite/">
              @sinophilic_toxophilite
            </Link>
            . I’m the person who came up with this idea in the first place and
            then started out on this project. I’ve got a love of changshaos and
            a moderate bow problem. I’m also the one who’s written most of code
            for this site - so if there are any problems, feel free to email me
            or to dm me on instagram. If you live near Toronto and have bows
            that you’d let me measure, just let me know :)
          </Typography>
          <br />
          <Typography>
            <Typography variant="h5">
              Mioshio <Typography>Arrow Fetishist</Typography>
            </Typography>
            The arrow guy. The one who spurred me on with making this site.
            Shares a brain cell with me and has a worse arrow problem than I
            have a bow problem. Fellow changshao and purple lover, he’s the one
            to blame for dark mode being purple. If you’re near NYC and you’ve
            got bows to measure, he’s the man.
          </Typography>
          <br />
          <Typography>
            <Typography variant="h5">
              Ben <Typography>The Horse</Typography>
            </Typography>
            <Link href="https://www.instagram.com/broth141/">@broth141</Link> on
            instagram and{" "}
            <Link href="https://www.reddit.com/user/Entropy-">Entropy-</Link> on
            reddit, I can’t tell if he’s a man or a horse. The man, the legend
            himself, provider of data, NA Distributer for AF Archery (Contact
            him directly{" "}
            <Link href="mailto:ro.archery.equine@outlook.com">here</Link> if you
            want to buy one). Probably the single largest source of data other
            than me, thanks to his archery business. Support his buisness if you
            want more AF Bows on this site :) if you’ve got bows in Oregon, hit
            him up :)
          </Typography>
          <br />
          <Typography>
            <Typography variant="h5">Oscar</Typography>
            Dutch bowyer extraordinaire - if you’re in the Netherlands or
            nearby, please hit him up for bow measuring
          </Typography>
          <br />
          <Typography>
            <Typography variant="h5">Tom</Typography>
            The naturalist; maker of leather, measurer of the land down under,
            lover of Turkish bows
          </Typography>
          <br />
          <Typography>
            <Typography variant="h5">
              Sunny <Typography>The """Casual"""</Typography>
            </Typography>
            He's a "casual". My replacement of a shooting machine, shoots with
            +/- 0.5fps repeatability. A veritable machine.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: theme => {
              return theme.palette.primary.main;
            },
          }}
        >
          <Typography variant="h4">How do I use this site</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h5">Basic level</Typography>
          <Typography>
            Use this site to look at what models of bows are available for
            purchase
          </Typography>
          <Typography variant="h5">Average User</Typography>
          <Typography>
            The average user will use this bow to compare bow purchase
            candidates with bows that they already own, or to get an idea of how
            they would feel with regards to the draw force curve
          </Typography>
          <Typography variant="h5">Nerd level</Typography>
          <Typography>
            Argue on the internet about how your bow is the best and how that
            guy's bow is bad, using objective measurements from this website (If
            you think your bow is poorly represented on this website, feel free
            to send me one to prove me wrong :))
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: theme => {
              return theme.palette.primary.main;
            },
          }}
        >
          <Typography variant="h4">How do I read the graphs</Typography>
        </AccordionSummary>
        <AccordionDetails>
          See this video{" "}
          <Link href="https://www.youtube.com/watch?v=rwa8b3fE_1A">
            that I made
          </Link>
          <Typography variant="h5">Single Bow Page</Typography>
          <Typography>
            {" "}
            There are 4 graphs viewable on this page. The default ones are the
            “regression curve” and the “regression derivative curve”. These are
            mathematical approximations of the draw force curve of the bow, and
            a mathematical approximation of the rate of change in draw force of
            the bow.
            <br />
            <br />
            The first one can be thought of as being the draw force curve of the
            bow. The regression derivative curve can be thought of as the
            stacking curve of the bow. The higher the value of that regression
            derivative curve, the more the bow “stacks”. Usually we percieve
            stacking around 5-6%.
            <br />
            <br />
            The remaining two curves are the “draw force curve” from measured
            data, and central differences. Central differences can be thought of
            as an approximation of the regression derivative curve calculated
            using the draw force curve data.
          </Typography>
          <Typography variant="h5">Multi Bow Page</Typography>
          <Typography>
            Select multiple bows to compare their draw force curves and their
            stacking curves.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: theme => {
              return theme.palette.primary.main;
            },
          }}
        >
          <Typography variant="h4">
            Can I use this site to say that my bow is better than x?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Short answer? No.
            <br />
            <br />
            Long answer: Whether a bow is better or not is dependant on a lot of
            variables, most of which are up to the individual. Some people like
            stacking, some people don't. This website does not take into account
            personal taste. All information on this site is intended to be
            objective measurements that can be quantified. At the end of the
            day, whether you like a large grip or a small grip, stacking or no
            stacking, that's up to you, and it does not mean that your bow is
            better than x.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: theme => {
              return theme.palette.primary.main;
            },
          }}
        >
          <Typography variant="h4">
            Can I get the data from this database in another format?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, see{" "}
            <Link href="https://github.com/notLiria/asiatic-bow-db-pgsql">
              here
            </Link>{" "}
            for a nightly pgsql dump.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default Faq;
