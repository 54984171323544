/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Scatter } from "react-chartjs-2";
import {
  FormGroup,
  FormControl,
  Tooltip,
  FormControlLabel,
  FormLabel,
  Grid,
  Switch,
  Slider,
  RadioGroup,
  Radio,
} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import Annotation from "chartjs-plugin-annotation";
import { Chart, LegendItem, registerables } from "chart.js";
// @ts-ignore
import ChartjsPluginWatermark from "chartjs-plugin-watermark";
import type { XYCurve } from "../../types/index.js";
import { hashToColor } from "../../utils/utils";
import { calcEnergyAtPoint } from "../../utils/math";
import { WATERMARK_SETTINGS } from "../../constants";

Chart.register(...registerables);
Chart.register(Annotation);
Chart.register(ChartjsPluginWatermark);

interface MultiBowGraphProps {
  bowData: XYCurve[];
  yLabel: string;
  showEnergy?: boolean;
}

const MultiBowGraph: React.FC<MultiBowGraphProps> = ({
  bowData,
  yLabel,
  showEnergy,
}) => {
  const units = yLabel.split("(")[1].slice(0, -1);
  const theme = useTheme();
  Chart.defaults.borderColor = theme.palette.grey["400"];
  const [showLineAt27, setShowLineAt27] = useState(false);
  const [normalizeType, setNormalize] = useState("none");
  const [normalizeX, setNormalizeX] = useState(false);
  const [showLineAt, setLineAt] = useState(27);
  const [lineMaxX, setMaxX] = useState(35);
  const [lineMinX, setMinX] = useState(0);

  useEffect(() => {
    let minX = Infinity;
    let maxX = -Infinity;

    bowData.forEach(item => {
      const { curve } = item;
      curve.forEach(point => {
        const x = point[0];
        if (x < minX) {
          minX = x;
        }
        if (x > maxX) {
          maxX = x;
        }
      });
    });

    setMaxX(maxX);
    setMinX(minX);
  }, [bowData]);

  useEffect(() => {
    if (normalizeX) {
      setLineAt(0);
      setMaxX(1);
      setMinX(0);
    } else {
      let minX = Infinity;
      let maxX = -Infinity;

      bowData.forEach(item => {
        const { curve } = item;
        curve.forEach(point => {
          const x = point[0];
          if (x < minX) {
            minX = x;
          }
          if (x > maxX) {
            maxX = x;
          }
        });
      });
      setLineAt(minX);
      setMaxX(maxX);
      setMinX(minX);
    }
  }, [normalizeX]);

  const datasets = bowData.map(data => {
    const backgroundColor = hashToColor(data.title);

    return {
      label: `${data.title}`,
      data: data.curve,
      showLine: true,
      borderWidth: 0.75,
      tension: 0.4,
      fill: false,
      backgroundColor,
      yAxisID: "lbs",
    };
  });

  if (normalizeType === "perY") {
    datasets.forEach((dataset, index) => {
      const maxY = Math.max(
        ...dataset.data.map(val => {
          return val[1];
        })
      );
      const minY = Math.min(
        ...dataset.data.map(val => {
          return val[1];
        })
      );
      const yRange = maxY - minY;
      datasets[index].data = dataset.data.map(val => {
        return [val[0], (val[1] - minY) / yRange];
      });
    });
  } else if (normalizeType === "allY") {
    const allYs = datasets.flatMap(dataset => {
      return dataset.data.map(val => {
        return val[1];
      });
    });
    const maxY = Math.max(...allYs);
    const minY = Math.min(...allYs);
    datasets.forEach((dataset, index) => {
      datasets[index].data = dataset.data.map(val => {
        return [val[0], (val[1] - minY) / maxY];
      });
    });
  }

  if (normalizeX) {
    datasets.forEach((dataset, i) => {
      const maxX = Math.max(
        ...dataset.data.map(val => {
          return val[0];
        })
      );
      const minX = Math.min(
        ...dataset.data.map(val => {
          return val[0];
        })
      );
      const xRange = maxX - minX;
      datasets[i].data = dataset.data.map(val => {
        return [(val[0] - minX) / xRange, val[1]];
      });
    });
  }
  const data = {
    datasets: [...datasets],
  };

  const options = {
    scales: {
      x: {
        display: true,
        type: "linear" as const,
        title: {
          text: "Draw length in inches from the belly",
          display: true,
        },
      },
      lbs: {
        display: true,
        position: "left" as const,
        min: 0,
        title: {
          text: yLabel,
          display: true,
        },
      },
    },
    plugins: {
      autocolors: false,
      tooltip: {
        callbacks: {
          label(context: any) {
            const returnVal = [
              `${context.dataset.label}: ${context.formattedValue}`,
            ];
            if (
              showEnergy &&
              normalizeX === false &&
              normalizeType === "none"
            ) {
              const relevantBow = bowData.find(sample => {
                return sample.title === context.dataset.label;
              });
              if (relevantBow?.regressionCoeffs) {
                const calculatedEnergy = calcEnergyAtPoint(
                  relevantBow.regressionCoeffs,
                  relevantBow.curve[0][0],
                  context.parsed.x
                );
                returnVal.push(
                  `Calculated energy: ${calculatedEnergy.toFixed(2)}J`
                );
              }
            }
            if (normalizeX === false && normalizeType === "none") {
              const formattedPoint = `(${
                context.parsed.x
              }in, ${context.parsed.y.toFixed(2)}${units})`;
              returnVal[0] = `${context.dataset.label}: ${formattedPoint}`;
            }
            return returnVal;
          },
        },
      },
      annotation: {
        annotations: [
          {
            display: showLineAt27,
            type: "line" as const,
            mode: "vertical" as const,
            scaleID: "x",
            value: showLineAt,
            borderColor: "rgb(255, 99, 132)",
            borderWidth: 2,
            label: {
              display: true,
              position: "end" as const,
              content: `${showLineAt} inches from the belly`,
            },
          },
        ],
      },
      legend: {
        labels: {
          filter(item: LegendItem) {
            return item.text !== "HIDE";
          },
        },
      },
    },
    watermark: WATERMARK_SETTINGS,
  };

  const handleNormalizationChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setNormalize(event.target.value);
  };

  const handleNormalizeX = () => {
    setNormalizeX(!normalizeX);
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (!showLineAt27) {
      setShowLineAt27(true);
    }
    if (!Array.isArray(newValue)) {
      setLineAt(newValue);
    }
  };
  return (
    <div>
      <Scatter data={data} options={options} />
      <div className="graphOptions" style={{ marginLeft: "50px" }}>
        <FormGroup>
          <FormControl sx={{ display: "inline-block" }}>
            <Grid container>
              <Grid item>
                <Tooltip
                  arrow
                  PopperProps={{ style: { margin: "4px 0" } }}
                  title="Display a vertical line at the desired value. Useful for figuring out poundage at your draw length"
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showLineAt27}
                        onChange={() => {
                          return setShowLineAt27(!showLineAt27);
                        }}
                      />
                    }
                    label="Show vertical line at"
                  />
                </Tooltip>
              </Grid>
              <Grid item xs>
                <Slider
                  size="small"
                  value={showLineAt}
                  min={lineMinX}
                  max={lineMaxX}
                  valueLabelDisplay="on"
                  step={normalizeX ? 0.1 : 1}
                  onChange={handleSliderChange}
                />
              </Grid>
            </Grid>
          </FormControl>
          <FormControlLabel
            control={
              <Tooltip
                title="Displays x values as a percentage of the maximum draw length"
                arrow
              >
                <Switch onChange={handleNormalizeX} value={normalizeX} />
              </Tooltip>
            }
            label="Normalize X values"
          />
          <FormControl sx={{ display: "inline-block" }}>
            <FormLabel>Y Axis Normalization</FormLabel>
            <RadioGroup
              value={normalizeType}
              onChange={handleNormalizationChange}
            >
              <FormControl sx={{ display: "inline-block" }}>
                <Tooltip title="Do not normalize any of the curves" arrow>
                  <FormControlLabel
                    value="none"
                    control={<Radio checked={normalizeType === "none"} />}
                    label="Do not normalize"
                  />
                </Tooltip>
              </FormControl>
              <FormControl sx={{ display: "inline-block" }}>
                <Tooltip
                  title="Displays y values of each curve as a % of the largest curve rather than as the measured values"
                  arrow
                >
                  <FormControlLabel
                    value="allY"
                    control={<Radio checked={normalizeType === "allY"} />}
                    label="Normalize along all curves"
                  />
                </Tooltip>
              </FormControl>
              <FormControl sx={{ display: "inline-block" }}>
                <Tooltip
                  title="Displays y values of each curve as a % of itself rather than as the measured values"
                  arrow
                >
                  <FormControlLabel
                    value="perY"
                    control={<Radio checked={normalizeType === "perY"} />}
                    label="Normalize along individual curves"
                  />
                </Tooltip>
              </FormControl>
            </RadioGroup>
          </FormControl>
        </FormGroup>
      </div>
    </div>
  );
};

MultiBowGraph.defaultProps = {
  showEnergy: false,
};
export default MultiBowGraph;
