/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Chart, registerables } from "chart.js";
import type { Point } from "chart.js";
import {
  FormGroup,
  FormControlLabel,
  FormControl,
  Grid,
  Switch,
  Slider,
  Tooltip,
  Stack,
} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme.js";
import useMediaQuery from "@mui/material/useMediaQuery";
// @ts-ignore
import ChartjsPluginWatermark from "chartjs-plugin-watermark";
import chartTrendline from "chartjs-plugin-trendline";
import { Scatter } from "react-chartjs-2";
import type { TorsionPoint } from "../../types/apiTypes";

import { MIN_SCREEN_WIDTH, WATERMARK_SETTINGS } from "../../constants";

Chart.register(...registerables);
Chart.register(ChartjsPluginWatermark);
Chart.register(chartTrendline);

interface SingleBowTorsionGraphProps {
  torsionData: TorsionPoint[];
  nominalPoundage?: number;
}
const SingleBowTorsionGraph: React.FC<SingleBowTorsionGraphProps> = ({
  torsionData,
  nominalPoundage,
}) => {
  const screenIsMinWidth = useMediaQuery(`(min-width:${MIN_SCREEN_WIDTH}px)`);
  const theme = useTheme();
  Chart.defaults.borderColor = theme.palette.grey["400"];

  const [normalize, setNormalize] = useState(false);

  const data = {
    datasets: [
      {
        label: "Torsional Stability Data",
        data: torsionData.map((point: TorsionPoint) => {
          if (normalize && nominalPoundage !== undefined) {
            return {
              x: (point.mass / (nominalPoundage * 454)) * 100,
              y: point.deflection,
            } as Point;
          }
          return { x: point.mass, y: point.deflection } as Point;
        }) as Point[],
        showLine: false,
        trendlineLinear: {
          lineStyle: "dotted",
          width: 2,
          projection: true,
        },
      },
    ],
  };

  const options = {
    scales: {
      x: {
        display: true,
        border: { width: 1.5, color: theme.palette.text.primary },
        type: "linear" as const,
        title: {
          text: normalize ? 'Mass in % of draw weight at 28"' : "Mass in g",
          display: true,
        },
        ticks: {
          callback: (value: any) => {
            return normalize ? `${value}%` : value;
          },
        },
      },
      y: {
        display: true,
        border: { width: 1.5, color: theme.palette.text.primary },
        position: "left" as const,
        title: {
          text: "Deflection (inches)",
          display: true,
        },
      },
    },
    plugins: {
      autocolors: false,
      title: {
        display: screenIsMinWidth,
        text: "Torsional Stabiilty",
      },
      responsive: true,
    },
    watermark: WATERMARK_SETTINGS,
  };

  return (
    <Stack>
      <Scatter data={data} options={options} />
      <FormControlLabel
        control={
          <Switch
            checked={normalize}
            onClick={() => {
              setNormalize(!normalize);
            }}
          />
        }
        label="Normalize data"
      />
    </Stack>
  );
};

SingleBowTorsionGraph.defaultProps = {
  nominalPoundage: 99999999,
};
export default SingleBowTorsionGraph;
