/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import { useTable, Column } from "react-table";
import "./DataEditor.css";

const isNum = (val: string) => {
  return /^\d+$/.test(val) && !/-/.test(val);
};

interface DataEditorProps {
  targetData: any;
  setTargetData: any;
}

interface EditableCellProps {
  value: any;
  row: { index: number };
  column: { id: number };
  updateMyData: (index: number, id: number, value: any) => void;
}

interface Data {
  property: string;
  value: any;
}

const EditableCell: React.FC<EditableCellProps> = ({
  value,
  row,
  column,
  updateMyData,
}) => {
  const [cellValue, setCellValue] = React.useState(value);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCellValue(e.target.value);
    updateMyData(
      row.index,
      column.id,
      isNum(e.target.value) ? parseFloat(e.target.value) : e.target.value
    );
  };

  return (
    <input
      value={cellValue}
      onChange={handleOnChange}
      className="data-editor-input"
    />
  );
};

const DataEditor: React.FC<DataEditorProps> = ({
  targetData,
  setTargetData,
}) => {
  const [editableData, setEditableData] = React.useState<Data[]>([]);
  // Initialize data with default values
  const data: Data[] = Object.keys(targetData).map(key => {
    return {
      property: key,
      value: targetData[key as string],
    };
  });

  useEffect(() => {
    const newData: Data[] = Object.keys(targetData).map(key => {
      return {
        property: key,
        value: targetData[key as string],
      };
    });
    setEditableData(newData);
  }, [targetData]);

  const updateMyData = (rowIndex: number, columnId: number, value: any) => {
    setEditableData(old => {
      const newData = old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            value, // only update the value field
          };
        }
        return row;
      });

      // Convert the array back to an object before setting the target data
      const newTargetData = newData.reduce<{ [key: string]: any }>(
        (obj, item) => {
          obj[item.property] = item.value;
          return obj;
        },
        {}
      );

      setTargetData(newTargetData);
      return newData;
    });
  };

  const columns: Column<Data>[] = React.useMemo(() => {
    return [
      {
        Header: "Property",
        accessor: "property", // accessor is the "key" in the data
        className: "property-column",
      },
      {
        Header: "Value",
        accessor: "value",
        Cell: EditableCell,
        CellProps: {
          updateMyData,
        },
      },
    ];
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<Data>({ columns, data: editableData });

  return (
    <table {...getTableProps()} className="data-editor-table">
      <thead>
        {headerGroups.map(headerGroup => {
          return (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="data-editor-th"
            >
              {headerGroup.headers.map((column, index) => {
                return (
                  <th
                    {...column.getHeaderProps()}
                    className={`data-editor-td ${
                      index === 0 ? "property-column" : ""
                    }`}
                  >
                    {column.render("Header")}
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td {...cell.getCellProps()}>
                    {cell.render("Cell", { updateMyData })}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default DataEditor;
