/* eslint-disable no-unused-vars */
import React from "react";
import { Chart, registerables } from "chart.js";
// @ts-ignore
import ChartjsPluginWatermark from "chartjs-plugin-watermark";
import BoxPlot from "../BoxPlot/BoxPlot";
import type { FpsDataPoint } from "../../types/apiTypes";
import { WATERMARK_SETTINGS } from "../../constants";

const pattern = require("patternomaly");
const colorsys = require("colorsys");

Chart.register(...registerables);
Chart.register(ChartjsPluginWatermark);

interface SingleBowFpsGraphProps {
  fpsData: FpsDataPoint[];
}

// eslint-disable-next-line no-unused-vars
const SingleBowFpsGraph: React.FC<SingleBowFpsGraphProps> = ({ fpsData }) => {
  // Helper functions
  const getUniqueValues = (
    data: FpsDataPoint[],
    key: keyof FpsDataPoint
  ): number[] => {
    return [
      ...new Set(
        data.map(item => {
          return item[key];
        })
      ),
    ];
  };
  const getColour = (length: number, index: number) => {
    return colorsys.hsv2Rgb(120 + (240 / length) * index, 100, 100);
  };
  const getPattern = (patternType: string, colour: any) => {
    return pattern.draw(
      patternType,
      `rgb(${colour.r}, ${colour.g}, ${colour.b}, 1)`
    );
  };

  // Get unique draw lengths and gpp values
  const drawLengths = getUniqueValues(fpsData, "dl");
  const gppValues = getUniqueValues(fpsData, "gpp").sort((a, b) => {
    return a - b;
  });

  // Initialize datasets
  const datasets = drawLengths.flatMap((drawLength, i) => {
    const dlColour = getColour(drawLengths.length, i);
    const efficiencyColour = getColour(drawLengths.length, i);

    return [
      {
        label: `FPS at ${drawLength}"`,
        data: gppValues.map(() => {
          return new Array(0);
        }),
        yAxisID: "fps",
        borderColor: `rgb(${dlColour.r}, ${dlColour.g}, ${dlColour.b}, 1)`,
        borderWidth: 1,
        outlierRadius: 3,
        itemRadius: 1,
        backgroundColor: getPattern("zigzag-vertical", dlColour),
      },
      /*
      {
        label: `Efficiency at ${drawLength}"`,
        data: [],
        yAxisID: "efficiency",
        borderColor: `rgb(${dlColour.r}, ${dlColour.g}, ${dlColour.b}, 1)`,
        borderWidth: 1,
        outlierRadius: 3,
        itemRadius: 1,
        backgroundColor: getPattern("plus", efficiencyColour),
      },
      */
    ];
  });

  // Group fpsData by draw length
  const fpsDataSortedByLength = drawLengths.reduce(
    (acc: Record<string, FpsDataPoint[]>, dl: number) => {
      acc[dl] = fpsData.filter(sample => {
        return sample.dl === dl;
      });
      return acc;
    },
    {}
  );

  Object.entries(fpsDataSortedByLength).forEach(([dl, dataAtDl]) => {
    const fpsDataset = datasets.find(dataset => {
      return dataset.label.includes(`FPS at ${dl}`);
    });
    const efficiencyDataset = datasets.find(dataset => {
      return dataset.label.includes(`Efficiency at ${dl}`);
    });

    const dataAtDlSortedByGpp = dataAtDl.reduce(
      (acc: Record<string, FpsDataPoint[]>, sample: FpsDataPoint) => {
        acc[sample.gpp] = acc[sample.gpp]
          ? [...acc[sample.gpp], sample]
          : [sample];
        return acc;
      },
      {}
    );
    Object.entries(dataAtDlSortedByGpp).forEach(([gpp, samples]) => {
      const index = gppValues.indexOf(parseFloat(gpp));
      if (fpsDataset) {
        fpsDataset.data[index] = samples.map(sample => {
          return sample.fps;
        });
      }

      if (efficiencyDataset) {
        efficiencyDataset.data[index] = samples.map(sample => {
          return sample.efficiency;
        });
      }
    });
  });

  // Format labels
  const labels = gppValues.map(label => {
    return label.toFixed(2);
  });

  // Define boxplotData

  const options = {
    scales: {
      fps: {
        type: "linear",
        display: true,
        beginAtZero: false,
        position: "left",
        title: {
          text: "FPS",
          display: true,
        },
      },
      x: {
        display: true,
        title: {
          text: "Grains per pound of draw weight",
          display: true,
        },
      },
    },
    watermark: WATERMARK_SETTINGS,
  };
  return (
    <BoxPlot
      data={{
        labels,
        datasets,
      }}
      options={options}
    />
  );
};

export default SingleBowFpsGraph;
