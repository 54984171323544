import type { Sample } from "../../types/apiTypes";
import type { OptionType } from "../../types";

const BowColumns = [
  {
    label: "Unstrung Length",
    value: "unstrung_length",
    extraProps: {
      name: "Unstrung Length (cm)",
      sortable: true,
      filterable: true,
      wrap: true,
      reorder: true,
      selector: (row: { sample: Sample }) => {
        if (row.sample.unstrungLength !== -1) {
          return row.sample.unstrungLength;
        }
        return "N/A";
      },
    },
  },
  {
    label: "Strung Length",
    value: "strung_length",
    extraProps: {
      name: "Strung Length (cm)",
      sortable: true,
      filterable: true,
      wrap: true,
      reorder: true,
      selector: (row: { sample: Sample }) => {
        if (row.sample.unstrungLength !== -1) {
          return row.sample.unstrungLength;
        }
        return "N/A";
      },
    },
  },
  {
    label: "Min Box Length",
    value: "min_box_length",
    extraProps: {
      name: "Min Box Length (cm)",
      sortable: true,
      filterable: true,
      wrap: true,
      reorder: true,
      selector: (row: { sample: Sample }) => {
        if (row.sample.minBoxLength !== -1) {
          return row.sample.minBoxLength;
        }
        return "N/A";
      },
    },
  },
  {
    label: "Min Box Width",
    value: "min_box_width",
    extraProps: {
      name: "Min Box Width (cm)",
      sortable: true,
      filterable: true,
      wrap: true,
      reorder: true,
      selector: (row: { sample: Sample }) => {
        if (row.sample.minBoxWidth !== -1) {
          return row.sample.minBoxWidth;
        }
        return "N/A";
      },
    },
  },
  {
    label: "Min Box Depth",
    value: "min_box_depth",
    extraProps: {
      name: "Min Box depth (cm)",
      sortable: true,
      filterable: true,
      wrap: true,
      reorder: true,
      selector: (row: { sample: Sample }) => {
        if (row.sample.minBoxDepth !== -1) {
          return row.sample.minBoxDepth;
        }
        return "N/A";
      },
    },
  },
  {
    label: "Grip Depth",
    value: "grip_depth",
    extraProps: {
      name: "Grip Depth (mm)",
      sortable: true,
      filterable: true,
      wrap: true,
      reorder: true,
      selector: (row: { sample: Sample }) => {
        if (row.sample.gripDepth !== -1) {
          return row.sample.gripDepth;
        }
        return "N/A";
      },
    },
  },
  {
    label: "Grip Length",
    value: "grip_length",
    extraProps: {
      name: "Grip length (mm)",
      sortable: true,
      filterable: true,
      wrap: true,
      reorder: true,
      selector: (row: { sample: Sample }) => {
        if (row.sample.gripLength !== -1) {
          return row.sample.gripLength;
        }
        return "N/A";
      },
    },
  },
  {
    label: "Grip Width",
    value: "grip_width",
    extraProps: {
      name: "Grip width (mm)",
      sortable: true,
      filterable: true,
      wrap: true,
      reorder: true,
      selector: (row: { sample: Sample }) => {
        if (row.sample.gripWidth !== -1) {
          return row.sample.gripWidth;
        }
        return "N/A";
      },
    },
  },
  {
    label: "Effective Siyah Top Length",
    value: "siyah_effective_top_length",
    extraProps: {
      name: "Siyah Effective Top Length (cm)",
      sortable: true,
      filterable: true,
      wrap: true,
      reorder: true,
      selector: (row: { sample: Sample }) => {
        if (row.sample.siyahEffectiveTopLength !== -1) {
          return row.sample.siyahEffectiveTopLength;
        }
        return "N/A";
      },
    },
  },
  {
    label: "Effective Siyah Top Angle",
    value: "siyah_effective_top_angle",
    extraProps: {
      name: "Siyah Effective Top Angle (degrees)",
      sortable: true,
      filterable: true,
      wrap: true,
      reorder: true,
      selector: (row: { sample: Sample }) => {
        if (row.sample.siyahEffectiveTopAngle !== -1) {
          return row.sample.siyahEffectiveTopAngle;
        }
        return "N/A";
      },
    },
  },
  {
    label: "Effective Siyah Bottom Length",
    value: "siyah_effective_bottom_length",
    extraProps: {
      name: "Siyah Effective Bottom Length (cm)",
      sortable: true,
      filterable: true,
      wrap: true,
      reorder: true,
      selector: (row: { sample: Sample }) => {
        if (row.sample.siyahEffectiveBottomLength !== -1) {
          return row.sample.siyahEffectiveBottomLength;
        }
        return "N/A";
      },
    },
  },
  {
    label: "Effective Siyah Bottom Angle",
    value: "siyah_effective_bottom_angle",
    extraProps: {
      name: "Siyah Effective Bottom Angle (degrees)",
      sortable: true,
      filterable: true,
      wrap: true,
      reorder: true,
      selector: (row: { sample: Sample }) => {
        if (row.sample.siyahEffectiveBottomAngle !== -1) {
          return row.sample.siyahEffectiveBottomAngle;
        }
        return "N/A";
      },
    },
  },
  {
    label: "Bow Mass",
    value: "bow_mass",
    extraProps: {
      name: "Bow Mass (g)",
      sortable: true,
      filterable: true,
      wrap: true,
      reorder: true,
      selector: (row: { sample: Sample }) => {
        if (row.sample.bowMass !== -1) {
          return row.sample.bowMass;
        }
        return "N/A";
      },
    },
  },
  {
    label: "Max Limb Thickness",
    value: "max_limb_thickness",
    extraProps: {
      name: "Max Limb Thickness (mm)",
      sortable: true,
      filterable: true,
      wrap: true,
      reorder: true,
      selector: (row: { sample: Sample }) => {
        return row.sample.maxLimbThickness !== -1
          ? row.sample.maxLimbThickness
          : "N/A";
      },
    },
  },
  {
    label: "Min Limb Thickness",
    value: "min_limb_thickness",
    extraProps: {
      name: "Min Limb Thickness (mm)",
      sortable: true,
      filterable: true,
      wrap: true,
      reorder: true,
      selector: (row: { sample: Sample }) => {
        return row.sample.minLimbThickness !== -1
          ? row.sample.minLimbThickness
          : "N/A";
      },
    },
  },
  {
    label: "Max Limb Width",
    value: "max_limb_width",
    extraProps: {
      name: "Max Limb Width (mm)",
      sortable: true,
      filterable: true,
      wrap: true,
      reorder: true,
      selector: (row: { sample: Sample }) => {
        return row.sample.maxLimbWidth !== -1 ? row.sample.maxLimbWidth : "N/A";
      },
    },
  },
  {
    label: "Min Limb Width",
    value: "min_limb_width",
    extraProps: {
      name: "Min Limb Width (mm)",
      sortable: true,
      filterable: true,
      wrap: true,
      reorder: true,
      selector: (row: { sample: Sample }) => {
        return row.sample.minLimbWidth !== -1 ? row.sample.minLimbWidth : "N/A";
      },
    },
  },
  {
    label: "Arrow Pass Width",
    value: "arrow_pass_width",
    extraProps: {
      name: "Arrow Pass Width (mm)",
      sortable: true,
      filterable: true,
      wrap: true,
      reorder: true,
      selector: (row: { sample: Sample }) => {
        return row.sample.arrowPassWidth !== -1
          ? row.sample.arrowPassWidth
          : "N/A";
      },
    },
  },
  {
    label: "Max Draw Length",
    value: "max_draw_length",
    extraProps: {
      name: "Max Draw Length (inches)",
      sortable: true,
      filterable: true,
      wrap: true,
      reorder: true,
      selector: (row: { sample: Sample }) => {
        return row.sample.maxDraw !== -1 ? row.sample.maxDraw : "N/A";
      },
    },
  },
] as OptionType[];

export default BowColumns;
