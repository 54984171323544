/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
import seedrandom from "seedrandom";
import axios from "axios";
import type { CleanedData, Point, OptionType, Update } from "../types/index.js";

import API_URL from "../constants";
import {
  addTagForBowType,
  getBowsWithTags,
  getComparisonSamples,
  getTags,
  updateBowType,
} from "./apiCalls";
import type {
  BowTypeWithTags,
  ComparisonSample,
  Tag,
  ShaftData,
  CollarData,
} from "../types/apiTypes.js";

interface Measurement {
  [key: string]: string;
}

const processBowCsv = (records: string[][]) => {
  // Keys is in the following format
  // "Bow Name" "Actual-Name-Of-Bow-In-Csv" "Bow Measurements" "Measurement"
  const bowName = Object.keys(records[0])[1];
  // So the Y column is actually keys[1]
  // We only want xy data where keys[1] (the Y column) is nonempty
  // This is actually draw force curve
  const xyData: Point[] = records
    .map((record: Object) => {
      const entries = Object.entries(record);
      const xVal = entries[0][1];
      const yVal = entries[1][1];
      return {
        x: xVal,
        y: yVal,
      };
    })
    .slice(1)
    .filter(val => {
      return val.y !== "";
    })
    .map(val => {
      return {
        x: parseFloat(val.x),
        y: parseFloat(val.y),
      };
    });

  const measurementArr: Measurement[] = records
    .map(record => {
      const entries = Object.entries(record);
      const varName = entries[2][1];
      const recordName = entries[3][1];
      return { [varName]: recordName };
    })
    .filter(row => {
      return Object.keys(row)[0] !== "";
    });

  const torsionValues: Point[] = records
    .map(record => {
      const entries = Object.entries(record);
      const xVal = entries[6][1];
      const yVal = entries[7][1];
      return {
        x: xVal,
        y: yVal,
      };
    })
    .filter(val => {
      return val.y !== "";
    })
    .map(val => {
      return {
        x: parseFloat(val.x),
        y: parseFloat(val.y),
      };
    });
  const output: CleanedData = {
    bowTitle: bowName,
    dfData: xyData,
    torsionData: torsionValues,
  };

  const propertyOrder = [
    "minBoxLength",
    "minBoxWidth",
    "minBoxDepth",
    "unstrungLength",
    "strungLength",
    "stockStringLengthMax",
    "stockStringLengthMin",
    "siyahEffectiveTopLength",
    "siyahEffectiveTopAngle",
    "siyahEffectiveBottomLength",
    "siyahEffectiveBottomAngle",
    "maxLimbThickness",
    "minLimbThickness",
    "maxLimbWidth",
    "minLimbWidth",
    "arrowPassWidth",
    "gripWidth",
    "gripDepth",
    "gripLength",
    "bowMass",
    "asym",
    "asymLengthTop",
    "asymLengthBottom",
    "braceHeight",
    "measurementDate",
    "manufactureDate",
    "comments",
    "contactInfoType",
    "contactInfo",
    "maxDraw",
    "nominalPoundage",
  ];

  propertyOrder.forEach((property, i) => {
    if (property !== "") {
      (output as any)[property] =
        parseFloat(Object.values(measurementArr[i])[0]) || -1;
    }
  });

  output.asym = Object.values(measurementArr[20])[0] === "Y";
  output.measurementDate = Object.values(measurementArr[24])[0];
  output.manufactureDate = Object.values(measurementArr[25])[0];
  output.comments = Object.values(measurementArr[26])[0];
  output.contributorContactInfo = Object.values(measurementArr[28])[0];
  output.contributorContactType = Object.values(measurementArr[27])[0];
  output.nominalPoundage = Object.values(measurementArr[30])[0];
  output.manufacturer = "";
  output.model = "";
  output.tags = [];
  output.submodel = "";
  return output;
};

export const EditableFields = [
  "bowTitle",
  "unstrungLength",
  "strungLength",
  "minBoxLength",
  "minBoxWidth",
  "minBoxDepth",
  "gripLength",
  "gripWidth",
  "gripDepth",
  "siyahEffectiveTopLength",
  "siyahEffectiveBottomLength",
  "siyahEffectiveTopAngle",
  "siyahEffectiveBottomAngle",
  "bowMass",
  "maxLimbThickness",
  "minLimbThickness",
  "maxLimbWidth",
  "minLimbWidth",
  "arrowPassWidth",
  "maxDraw",
  "stockStringLengthMax",
  "stockStringLengthMin",
  "braceHeight",
  "manufactureDate",
  "measurementDate",
  "comments",
  "asym",
  "asymLengthTop",
  "asymLengthBottom",
  "dfData",
  "contributorContactType",
  "contributorContactInfo",
  "submodel",
  "nominalPoundage",
  "torsionData",
];

export const SampleFields = [
  "bowTypeId",
  "unstrungLength",
  "strungLength",
  "minBoxLength",
  "minBoxWidth",
  "minBoxDepth",
  "siyahEffectiveTopLength",
  "siyahEffectiveBottomLength",
  "siyahEffectiveTopAngle",
  "siyahEffectiveBottomAngle",
  "bowMass",
  "gripLength",
  "gripWidth",
  "gripDepth",
  "maxLimbThickness",
  "minLimbThickness",
  "maxLimbWidth",
  "minLimbWidth",
  "arrowPassWidth",
  "maxDraw",
  "stockStringLengthMin",
  "stockStringLengthMax",
  "braceHeight",
  "contributorContactType",
  "contributorContactInfo",
  "manufactureDate",
  "measurementDate",
  "comments",
  "asym",
  "asymLengthTop",
  "asymLengthBottom",
  "dfData",
  "submodel",
  "nominalPoundage",
];

export const getSelectBowOptions = async (): Promise<OptionType[]> => {
  const bowTypesWithTags = await getBowsWithTags();
  return bowTypesWithTags.map((bowType: BowTypeWithTags) => {
    return {
      label: `${bowType.manufacturer} - ${bowType.modelName}`,
      value: `${bowType.bowTypeId}`,
      extraProps: { ...bowType },
    } as OptionType;
  });
};

export const getSelectTagOptions = async (): Promise<OptionType[]> => {
  const fetchedTags = await getTags();
  return fetchedTags.map((tag: Tag) => {
    return {
      label: tag.tagName,
      value: `${tag.tagId}`,
      extraProps: { ...tag },
    };
  });
};

export const getBowComparisonOptions = async () => {
  const comparisonSamples = await getComparisonSamples();
  const searchOptions: OptionType[] = comparisonSamples.map(
    (sample: ComparisonSample) => {
      let label = `${sample.manufacturer} - ${sample.modelName}`;
      if (sample.submodel) {
        label = `${label} - ${sample.submodel}`;
      }
      label = `${label} - #${sample.sampleId}`;
      if (sample.nominalPoundage !== null) {
        label = `${label} - ${sample.nominalPoundage}`;
      }
      return {
        label,
        value: `${sample.sampleId}`,
        extraProps: sample,
      };
    }
  );
  return searchOptions.sort((a, b) => {
    return a.label.localeCompare(b.label);
  });
};

export const convertPathToDfCurve = (dfCurveArray: number[][]) => {
  return dfCurveArray.map(point => {
    return {
      x: point[0],
      y: point[1],
    };
  });
};

export const processShaftData = (shaftData: any[]) => {
  return shaftData.map(
    (shaft: {
      [x: string]: any;
      Company: any;
      Model: any;
      spine: any;
      GPI: any;
      OD: any;
      ID: any;
      Comments: any;
    }) => {
      return {
        manufacturer: shaft.Company,
        model: shaft.Model,
        spine: parseFloat(shaft.Spine),
        gpi: parseFloat(shaft.GPI) || -1,
        od: parseFloat(shaft.OD) || -1,
        id: parseFloat(shaft.ID) || -1,
        stockLength: parseFloat(shaft["Stock Length"]) || -1,
        insertStemLength: parseFloat(shaft["Insert Stem Length"]) || -1,
        insertRim: parseFloat(shaft["Insert Rim Length"]) || -1,
        bushingNockInnerLength:
          parseFloat(shaft["Bushing/Nock Inner Length"]) || -1,
        bushingOuterLength: parseFloat(shaft["Bushing Outer Length"]) || -1,
        insertWeight: parseFloat(shaft["Insert weight"]) || -1,
        bushingNockWeight: parseFloat(shaft["Bushing/Nock weight"]) || -1,
        comments: shaft.Comments,
      } as ShaftData;
    }
  );
};

export const processCollarCsv = (collarCsv: any[]) => {
  return collarCsv.map(collar => {
    return {
      manufacturer: collar.Brand,
      model: collar.Model.trim(),
      shaftSize: Number.isNaN(parseFloat(collar["Shaft Size"]))
        ? -1
        : parseFloat(collar["Shaft Size"]),
      outsertId: Number.isNaN(parseFloat(collar["Outsert ID"]))
        ? -1
        : parseFloat(collar["Outsert ID"]),
      outsertOd: Number.isNaN(parseFloat(collar["Outsert OD"]))
        ? -1
        : parseFloat(collar["Outsert OD"]),
      pointSize: Number.isNaN(parseFloat(collar["Point Size"]))
        ? -1
        : parseFloat(collar["Point Size"]),
      weight: Number.isNaN(parseFloat(collar.Weight))
        ? -1
        : parseFloat(collar.Weight),
    } as CollarData;
  });
};

function rgbToHex(r: number, g: number, b: number) {
  return [r, g, b]
    .map(x => {
      const hex = Math.round(x).toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    })
    .join("");
}

export const hashToColor = (str: string) => {
  let seed = 0;
  for (let i = 0; i < str.length; i++) {
    seed = (seed * 31 + str.charCodeAt(i)) % 16777216; // Modulus 16777216 for 24-bit color range
  }

  // Assuming seedrandom is a function that initializes a new pseudo-random generator
  const random = seedrandom(seed.toString());

  let r = Math.floor(random() * 256);
  let g = Math.floor(random() * 256);
  let b = Math.floor(random() * 256);

  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Adjust the color to ensure minimum luminance if necessary
  if (luminance < 0.3) {
    const adjustFactor = 0.3 / luminance;
    r = Math.min(255, r * adjustFactor);
    g = Math.min(255, g * adjustFactor);
    b = Math.min(255, b * adjustFactor);
  }

  // Convert RGB to Hex
  return `#${rgbToHex(r, g, b)}`;
};

export const parseFpsCsv = (data: any) => {
  // Identify GPP buckets
  // Move all FPS into GPP buckets, like {9.541: [181, 176, etc]}
  // Filter the buckets for
};

export const updateBow = async (data: {
  bowTypeId: number;
  manufacturer: string;
  modelName: string;
  bowLink: string;
  tags: number[];
}) => {
  try {
    const res = await updateBowType(data.bowTypeId, {
      modelName: data.modelName,
      manufacturer: data.manufacturer,
      bowLink: data.bowLink,
    });
    data.tags.forEach(async (tagId: number) => {
      await addTagForBowType(data.bowTypeId, tagId);
    });
    return res.data;
  } catch (e) {
    return [];
  }
};

export default processBowCsv;
