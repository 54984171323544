import type { Collar } from "../../types/apiTypes";

const collarDataColumns = [
  {
    id: "manufacturer",
    name: "Manufacturer",
    sortable: true,
    filterable: true,
    wrap: true,
    width: "150px",
    selector: (row: any) => {
      return row.manufacturer;
    },
  },
  {
    id: "model",
    name: "Model",
    sortable: true,
    filterable: true,
    wrap: true,
    width: "400px",
    selector: (row: any) => {
      return row.model;
    },
  },
  {
    id: "outsert_id",
    name: "Outsert ID",
    sortable: true,
    filterable: true,
    reorder: true,
    width: "75px",
    selector: (row: any) => {
      return row.outsertId;
    },
    format: (row: Collar) => {
      if (row.outsertId !== -1) {
        return row.outsertId;
      }
      return "N/A";
    },
  },
  {
    id: "outsert_od",
    name: "Outsert OD",
    sortable: true,
    reorder: true,
    filterable: true,
    width: "75px",
    selector: (row: any) => {
      return row.outsertOd;
    },
    format: (row: Collar) => {
      if (row.outsertOd !== -1) {
        return row.outsertOd;
      }
      return "N/A";
    },
  },
  {
    id: "point_size",
    name: "Point size",
    sortable: true,
    reorder: true,
    filterable: true,
    width: "75px",
    selector: (row: any) => {
      return row.pointSize;
    },
    format: (row: Collar) => {
      if (row.pointSize !== -1) {
        return row.pointSize;
      }
      return "N/A";
    },
  },
  {
    id: "shaft_size",
    name: "Shaft size",
    sortable: true,
    reorder: true,
    filterable: true,
    width: "75px",
    selector: (row: any) => {
      return row.shaftSize;
    },
    format: (row: Collar) => {
      if (row.shaftSize !== -1) {
        return row.shaftSize;
      }
      return "N/A";
    },
  },
  {
    id: "weight",
    name: "Weight (Grain)",
    sortable: true,
    reorder: true,
    filterable: true,
    width: "75px",
    selector: (row: any) => {
      return row.weight;
    },
    format: (row: Collar) => {
      if (row.weight !== -1) {
        return row.weight;
      }
      return "N/A";
    },
  },
];

export default collarDataColumns;
