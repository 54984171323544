import React from "react";
import { Tooltip, Typography, Box, List, ListItem } from "@mui/material";
import { MathJax } from "better-react-mathjax";
import "./BowData.css";

import type { Sample } from "../../types/apiTypes";

import renderTooltipVals from "./renderTooltipVals";
import ToggleableListItem from "./ToggleableListItem/ToggleableListItem";

interface BowDataProps {
  sample: Sample;
}

const bracketifyEqn = (eqn: string) => {
  // Define the pattern to match each term
  const pattern = /([-+]?\d*\.?\d+(?:[eE][-+]?\d+)?x)/g;

  // Find all matches and replace with brackets
  const bracketedEqn = eqn.replace(pattern, "{$1}");

  return bracketedEqn;
};

const BowData: React.FC<BowDataProps> = ({ sample }) => {
  const filteredData = renderTooltipVals.map(tooltipVal => {
    return {
      key: tooltipVal.val,
      description: tooltipVal.description,
      value:
        sample[tooltipVal.val as keyof Sample] !== null
          ? sample[tooltipVal.val as keyof Sample]
          : -1,
    };
  });

  return (
    <div className="bowDataContainer">
      <Box sx={{ overflow: "auto", textOverflow: "ellipsis" }} component="div">
        <List>
          {filteredData.map(entry => {
            if (Array.isArray(entry.value) && Array.isArray(entry.value[0])) {
              // Entry is an array; x-y values
              return (
                <ListItem key={entry.key}>
                  <ToggleableListItem
                    data={entry.value as number[][]}
                    title={entry.key.replaceAll("_", " ")}
                    description={entry.description}
                  />
                </ListItem>
              );
            }
            if (Array.isArray(entry.value)) {
              // Coefficient array
              return (
                <ListItem divider key={entry.key}>
                  <Tooltip title={entry.description} arrow>
                    <Typography variant="subtitle1">
                      {entry.key.replaceAll("_", " ")}
                    </Typography>
                  </Tooltip>

                  <Typography variant="body1">
                    {": "} {entry.value.join(", ")}
                  </Typography>
                </ListItem>
              );
            }
            if (
              entry.key.includes("regression") &&
              !Array.isArray(entry.value) &&
              entry.value !== -1
            ) {
              const entryValue = (entry.value as string)
                .replaceAll("(", "")
                .replaceAll(")", "");
              const bracketified = bracketifyEqn(entryValue);
              return (
                <ListItem divider key={entry.key}>
                  <Tooltip title={entry.description} arrow>
                    <Typography variant="subtitle1">
                      {entry.key.replaceAll("_", " ")}
                    </Typography>
                  </Tooltip>
                  <MathJax>
                    <Typography variant="body1">
                      {": "}
                      {entry.value === -1
                        ? "Not measured"
                        : `\\(${bracketified}\\)`}
                    </Typography>
                  </MathJax>
                </ListItem>
              );
            }
            return (
              <ListItem divider key={entry.key}>
                <Tooltip title={entry.description} arrow>
                  <Typography variant="subtitle1">
                    {entry.key.replaceAll("_", " ")}
                  </Typography>
                </Tooltip>
                <Typography variant="body1">
                  {": "}
                  {entry.value === -1 ? "Not measured" : entry.value}
                </Typography>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </div>
  );
};

export default BowData;
