/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "@mui/material";
import DataEditor from "../DataEditor/DataEditor";
import SearchBar from "../SearchBar/SearchBar";
import API_URL from "../../constants";

import {
  convertPathToDfCurve,
  EditableFields,
  getSelectBowOptions,
} from "../../utils/utils";
import type { OptionType, EditableSample, BowData } from "../../types/index.js";
import SingleBowGraph from "../SingleBowGraph/SingleBowGraph";
import SendToDbButton from "../SendToDbButton/SendToDbButton";
import {
  getSample,
  getSamplesForBowType,
  updateSample,
} from "../../utils/apiCalls";
import type { SampleSummary, Sample } from "../../types/apiTypes";

const ModifySample: React.FC = () => {
  const [options, setOptions] = useState<OptionType[]>([]);
  const [samples, setSamples] = useState<SampleSummary[]>([]);
  const [selectedBow, setSelectedBow] = useState<OptionType | null>(null);
  const [selectedSample, setSelectedSample] = useState<EditableSample | Object>(
    {}
  );
  const [selectedSampleId, setSelectedSampleId] = useState(-1);
  const [selectedButton, setSelectedButton] = useState<number | null>(null);
  const [editedDfData, setEditedDfData] = useState<BowData | null>(null);

  useEffect(() => {
    const fetcher = async () => {
      const bowOptions = await getSelectBowOptions();
      setOptions(bowOptions);
    };
    fetcher();
  }, []);

  const handleSelectChange = async (selectedOption: OptionType | null) => {
    if (selectedOption) {
      setSelectedBow(selectedOption);
      try {
        const response = await getSamplesForBowType(
          parseFloat(selectedOption.value)
        );
        setSamples(response);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(
          `Failed to fetch samples for bow ${selectedOption.value}`,
          error
        );
      }
    }
  };

  const handleButtonClick = async (sampleId: number) => {
    setSelectedSample({});
    setEditedDfData(null);
    const sampleData = await getSample(sampleId);
    setSelectedButton(sampleId);
    setSelectedSampleId(sampleId);
    if (!Array.isArray(sampleData)) {
      const editableData: EditableSample = Object.keys(sampleData)
        .filter((key): key is keyof typeof sampleData => {
          return EditableFields.includes(key);
        })
        .reduce((obj, key) => {
          // eslint-disable-next-line no-param-reassign
          obj[key] = sampleData[key] || "";
          return obj;
        }, {} as any) as EditableSample;
      editableData.dfData = JSON.stringify(
        convertPathToDfCurve(editableData.dfData)
      );
      setEditedDfData({
        regression_curve: [],
        regression_derivative_values: [],
        df_data: JSON.parse(editableData.dfData),
        central_differences: [],
      });
      setSelectedSample(editableData);
    }
  };

  const handleModifyField = (data: EditableSample) => {
    const outputData: { [key: string]: any } = {};
    Object.keys(data).forEach(key => {
      const value = data[key as keyof EditableSample];
      outputData[key] = "";
      // Value can either be a JSON, a string, a boolean, or a number
      // A number or a string are valid JSON but not vice versa
      // Only df_data should be a JSON
      if (key === "dfData") {
        let parsedData;
        try {
          parsedData = JSON.parse(value);
        } catch (e) {
          parsedData = value;
        }
        setEditedDfData({
          regression_curve: [],
          regression_derivative_values: [],
          df_data: parsedData,
          central_differences: [],
        });
        outputData[key] = parsedData;
      } else if (key === "asym") {
        if (value !== "") {
          outputData[key] = JSON.parse(value);
        } else {
          outputData[key] = false;
        }
      } else if (
        [
          "comments",
          "contributorContactInfo",
          "contributorContactType",
          "manufactureDate",
          "measurementDate",
          "submodel",
          "nominalPoundage",
        ].includes(key)
      ) {
        outputData[key] = value;
      } else {
        outputData[key] = JSON.parse(value);
      }
    });
    setSelectedSample(outputData);
  };

  const prepForSend = (data: EditableSample | Object, sampleId: number) => {
    const modifiedData = { ...data };
    if ("dfData" in modifiedData && typeof modifiedData.dfData === "object") {
      modifiedData.dfData = JSON.stringify(modifiedData.dfData);
    }
    return { ...modifiedData, sampleId };
  };

  const sendToDb = (data: any) => {
    return updateSample(data);
  };
  return (
    <div>
      <SearchBar
        name={options.length > 0 ? "Select a bow" : "Loading bow options"}
        onChange={handleSelectChange}
        options={options}
        forcedValue={selectedBow}
      />

      <div>
        <div>
          {samples.map((sample: SampleSummary) => {
            return (
              <Button
                key={sample.sampleId}
                variant={
                  selectedButton === sample.sampleId ? "contained" : "outlined"
                }
                onClick={() => {
                  return handleButtonClick(sample.sampleId);
                }}
              >
                Sample {sample.sampleId}
              </Button>
            );
          })}
        </div>
        <div>
          {selectedSample !== null && (
            <div>
              <DataEditor
                targetData={selectedSample}
                setTargetData={handleModifyField}
              />
              <div>
                {editedDfData !== null && (
                  <div>
                    <SingleBowGraph bowData={editedDfData} />
                  </div>
                )}
              </div>
              <div>
                <SendToDbButton
                  data={prepForSend(selectedSample, selectedSampleId)}
                  f={sendToDb}
                  endpoint="api/sample/modify"
                  successMessage="sent to db!"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModifySample;
