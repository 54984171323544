/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import TopBarProgress from "react-topbar-progress-indicator";
import CollapsibleListItem from "./CollapsibleListItem/CollapsibleListItem";
import { getSelectBowOptions } from "../../utils/utils";
import type { OptionType } from "../../types";
import type { DataUpdate } from "../../types/apiTypes";
import { getDataUpdates } from "../../utils/apiCalls";

const groupByBowTypeId = (data: DataUpdate[]): DataUpdate[][] => {
  const groupedData = new Map();
  let result: (DataUpdate | DataUpdate[] | number)[] = [];

  data.forEach(item => {
    if (item.bowTypeId === null) {
      result.push(item);
    }
    if (item.bowTypeId !== null && item.bowTypeId !== undefined) {
      result.push(item.bowTypeId);
      if (groupedData.get(item.bowTypeId) === undefined) {
        groupedData.set(item.bowTypeId, [item]);
      } else {
        groupedData.set(item.bowTypeId, [
          ...groupedData.get(item.bowTypeId),
          item,
        ]);
      }
    }
  });

  groupedData.forEach((group: DataUpdate, key: number) => {
    const placeholderIndex = result.indexOf(key);
    result[placeholderIndex] = group;
    result = result.filter((entry: DataUpdate | DataUpdate[] | number) => {
      return entry !== key;
    });
  });

  return result as DataUpdate[][];
};

interface UpdateDisplayProps {
  setSelectedBow: (value: OptionType | null) => void;
  setSelectedButton: (value: number) => void;
}

const UpdateDisplay: React.FC<UpdateDisplayProps> = ({
  setSelectedBow,
  setSelectedButton,
}) => {
  const [rawUpdates, setRawUpdates] = useState<DataUpdate[]>([]);
  const [updates, setUpdates] = useState<DataUpdate[][]>([]);
  const [bows, setBows] = useState<OptionType[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetcher = async () => {
      const fetchedUpdates = await getDataUpdates(currentPage);
      setRawUpdates(fetchedUpdates);
      const fetchedBows = await getSelectBowOptions();
      setBows(fetchedBows);
      setCurrentPage(currentPage + 1);
    };
    fetcher();
  }, []);

  useEffect(() => {
    setUpdates(groupByBowTypeId(rawUpdates));
  }, [rawUpdates]);

  const updateToStringArray = (update: DataUpdate) => {
    const dateString = new Date(update.modificationDate);
    const updateText = [
      `${dateString.getFullYear()}/${dateString.getMonth()}/${dateString.getDate()}`,
      " Modified ",
    ];
    if (update.bowTypeId !== null) {
      const modifiedBow = bows.find(el => {
        return parseFloat(el.value) === update.bowTypeId;
      });

      updateText.push(`${modifiedBow?.label}`);
    }
    if (update.sampleId !== null) {
      updateText.push(`Updated sample #${update.sampleId}`);
    }
    if (update.pictureId !== null) {
      updateText.push(`Added pictures`);
    }
    if (update.fpsId !== null || update.fpsRegressionId !== null) {
      updateText.push(`Added FPS data`);
    }
    return updateText;
  };

  const formatUpdate = (update: DataUpdate) => {
    const updateText = updateToStringArray(update);
    if (
      update.bowTypeId !== null &&
      update.sampleId !== null &&
      update.sampleId !== undefined
    ) {
      const modifiedBow = bows.find(el => {
        return parseFloat(el.value) === update.bowTypeId;
      });
      if (modifiedBow !== undefined) {
        return (
          <ListItem key={JSON.stringify(update)} sx={{ pl: 4 }}>
            {modifiedBow ? (
              <div>
                <Button
                  startIcon={
                    <CircleIcon
                      sx={{
                        transform: "scale(0.5)",
                        color: theme => {
                          return theme.palette.primary.contrastText;
                        },
                      }}
                    />
                  }
                  variant="text"
                  sx={{
                    m: 0,
                    p: 0,
                    color: theme => {
                      return theme.palette.primary.light;
                    },
                  }}
                  onClick={e => {
                    setSelectedBow(modifiedBow);
                    setSelectedButton(update.sampleId as number);
                  }}
                >
                  {modifiedBow.label}
                </Button>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ pl: 3 }}
                >
                  {updateText.join(" - ")}
                </Typography>
              </div>
            ) : (
              <ListItemText primary={updateText.join("-")} />
            )}
          </ListItem>
        );
      }
    }
    return (
      <ListItem key={JSON.stringify(update)} sx={{ pl: 4 }}>
        <div>
          <Button
            startIcon={
              <CircleIcon
                sx={{
                  transform: "scale(0.5)",
                  color: theme => {
                    return theme.palette.primary.contrastText;
                  },
                }}
              />
            }
            variant="text"
            sx={{
              m: 0,
              p: 0,
              color: theme => {
                return theme.palette.primary.light;
              },
            }}
            disabled
          >
            {updateText[2]}
          </Button>
          <Typography variant="body2" color="text.secondary" sx={{ pl: 3 }}>
            {updateText.join(" - ")}
          </Typography>
        </div>
      </ListItem>
    );
  };

  const handleRequestMoreUpdates = async () => {
    const fetchedUpdates = await getDataUpdates(currentPage);
    setCurrentPage(currentPage + 1);
    setRawUpdates([...rawUpdates, ...fetchedUpdates]);
  };

  return (
    <Box
      sx={{
        width: "80%",
        top: "50%",
        left: "50%",
        position: "absolute" as "absolute",
        transform: "translate(-50%, -50%)",
        maxHeight: "80%",
        overflowY: "scroll",
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
      }}
    >
      <Typography sx={{ pl: 5, pt: 5 }} variant="h5">
        {" "}
        Recent Updates{" "}
      </Typography>
      {updates.length === 0 && <TopBarProgress />}
      <List sx={{ listStyleType: "disc", listStylePosition: "inside" }}>
        {updates.map((update: DataUpdate[] | DataUpdate) => {
          if (Array.isArray(update) && update.length !== 1) {
            const modifiedBow = bows.find(el => {
              return parseFloat(el.value) === update[0].bowTypeId;
            });
            return (
              <CollapsibleListItem
                items={update}
                bowTitle={modifiedBow?.label || ""}
                bows={bows}
                setSelectedBow={setSelectedBow}
                setSelectedButton={setSelectedButton}
              />
            );
          }
          if (Array.isArray(update)) {
            return formatUpdate(update[0]);
          }
          return formatUpdate(update);
        })}
      </List>
      <Button
        variant="outlined"
        sx={{ m: 5 }}
        onClick={handleRequestMoreUpdates}
      >
        Show more updates
      </Button>
    </Box>
  );
};

export default UpdateDisplay;
