/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MathJax } from "better-react-mathjax";
import { getSelectTagOptions } from "../../utils/utils";
import type { OptionType } from "../../types";
import definitions from "./itemDefinitions";

const Glossary = () => {
  // eslint-disable-next-line no-unused-vars
  const [allTags, setAllTags] = useState<OptionType[]>();
  useEffect(() => {
    const fetcher = async () => {
      const fetchedTags = await getSelectTagOptions();
      setAllTags(fetchedTags);
    };
    fetcher();
  }, []);

  return (
    <div style={{ maxWidth: "70%" }}>
      Below is a short description of what each label/measurment on this site
      is, and what it is used for. For more detailed explanations of measured
      variables, see the PDF on the Contributing page.
      {definitions.map(el => {
        return (
          <Accordion sx={{ paddingBottom: "5px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                backgroundColor: theme => {
                  return theme.palette.primary.main;
                },
              }}
            >
              <Typography
                sx={{
                  color: theme => {
                    return theme.palette.primary.contrastText;
                  },
                }}
              >
                {el.key}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MathJax>{el.description}</MathJax>
            </AccordionDetails>
          </Accordion>
        );
      })}
      {allTags !== undefined && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Tags</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              {allTags.map(tag => {
                return (
                  <li>
                    {tag.label} : {tag.extraProps.tagDescription}
                  </li>
                );
              })}
            </ul>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};

export default Glossary;
