/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Typography,
  Collapse,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import type { Update, OptionType } from "../../../types";
import type { DataUpdate } from "../../../types/apiTypes";

interface CollapsibleListItemProps {
  items: DataUpdate[];
  bows: OptionType[];
  bowTitle: string;
  setSelectedBow: (value: OptionType | null) => void;
  setSelectedButton: (value: number) => void;
}

const CollapsibleListItem: React.FC<CollapsibleListItemProps> = ({
  items,
  bowTitle,
  bows,
  setSelectedBow,
  setSelectedButton,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  const updateToStringArray = (update: DataUpdate) => {
    const dateString = new Date(update.modificationDate);
    const updateText = [
      `${dateString.getFullYear()}/${dateString.getMonth()}/${dateString.getDate()}`,
      " Modified ",
    ];
    if (update.bowTypeId !== null) {
      const modifiedBow = bows.find(el => {
        return parseFloat(el.value) === update.bowTypeId;
      });

      updateText.push(`${modifiedBow?.label}`);
    }
    if (update.sampleId !== null) {
      updateText.push(`Updated sample #${update.sampleId}`);
    }
    if (update.pictureId !== null) {
      updateText.push(`Added pictures`);
    }
    if (update.fpsId !== null || update.fpsRegressionId !== null) {
      updateText.push(`Added FPS data`);
    }
    return updateText;
  };

  const formatUpdate = (update: DataUpdate) => {
    const updateText = updateToStringArray(update);
    if (
      update.bowTypeId !== null &&
      update.sampleId !== null &&
      update.sampleId !== undefined
    ) {
      const modifiedBow = bows.find(el => {
        return parseFloat(el.value) === update.bowTypeId;
      });
      if (modifiedBow !== undefined) {
        return (
          <ListItem key={JSON.stringify(update)} sx={{ pl: 4 }}>
            {modifiedBow ? (
              <div>
                <Button
                  startIcon={
                    <CircleIcon
                      sx={{
                        transform: "scale(0.5)",
                        color: theme => {
                          return theme.palette.primary.contrastText;
                        },
                      }}
                    />
                  }
                  variant="text"
                  sx={{
                    m: 0,
                    p: 0,
                    color: theme => {
                      return theme.palette.primary.light;
                    },
                  }}
                  onClick={e => {
                    setSelectedBow(modifiedBow);
                    setSelectedButton(update.sampleId as number);
                  }}
                >
                  {modifiedBow.label}
                </Button>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ pl: 3 }}
                >
                  {updateText.join(" - ")}
                </Typography>
              </div>
            ) : (
              <ListItemText primary={updateText.join("-")} />
            )}
          </ListItem>
        );
      }
    }
    return (
      <ListItem key={JSON.stringify(update)} sx={{ pl: 4 }}>
        <div>
          <Button
            startIcon={
              <CircleIcon
                sx={{
                  transform: "scale(0.5)",
                  color: theme => {
                    return theme.palette.primary.contrastText;
                  },
                }}
              />
            }
            variant="text"
            sx={{
              m: 0,
              p: 0,
              color: theme => {
                return theme.palette.primary.light;
              },
            }}
            disabled
          >
            {updateText[2]}
          </Button>
          <Typography variant="body2" color="text.secondary" sx={{ pl: 3 }}>
            {updateText.join(" - ")}
          </Typography>
        </div>
      </ListItem>
    );
  };

  return (
    <List sx={{ pl: 2 }}>
      <Button
        onClick={handleClick}
        sx={{
          pl: 2,
          color: theme => {
            return theme.palette.primary.light;
          },
        }}
        startIcon={open ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
      >
        {bowTitle}
      </Button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          sx={{ listStyleType: "disc", pl: 4, listStylePosition: "inside" }}
          component="div"
          disablePadding
        >
          {items.map(update => {
            return formatUpdate(update);
          })}
        </List>
      </Collapse>
    </List>
  );
};

export default CollapsibleListItem;
