/* eslint-disable react/no-unescaped-entities */
import { Typography } from "@mui/material";
import API_URL from "../../constants";

const Contributing = () => {
  return (
    <div>
      <Typography variant="h2">Here's how you can contribute</Typography>
      <Typography variant="body1">
        Contribute bow data by filling out{" "}
        <a href={`${API_URL}/api/data_template`}>this spreadsheet</a> according
        to the{" "}
        <a href={`${API_URL}/api/measurement_guide`}>measurement guide</a> and
        contact me via email{" "}
        <a href="mailto:asiaticbowdatabase@gmail.com"> here</a>
      </Typography>

      <Typography variant="h3">Alternatively</Typography>
      <Typography variant="body1">
        If you live near one of these locales, please{" "}
        <a href="mailto:asiaticbowdatabase@gmail.com"> send me an email</a> and
        I'll put you in touch with someone who has a setup to measure your bows
        with :)
        <ul>
          <li> Toronto, Canada</li>
          <li> New York City, New York, USA</li>
          <li>
            {" "}
            Portland, Oregon, USA (Email Ben Roth directly{" "}
            <a href="mailto:ro.archery.equine@outlook.com">here</a>)
          </li>
          <li> Melbourne, Australia</li>
          <li> Amersfoort, Netherlands </li>
        </ul>
        And if you're interested in joining in in being a bow measurer, please
        email me!
      </Typography>

      <Typography variant="body1">
        Or if you'd like to support this project financially, you can make a
        donation <a href="https://ko-fi.com/notliria">here!</a>
      </Typography>
    </div>
  );
};

export default Contributing;
