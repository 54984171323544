/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import ImageDisplayer from "./ImageDisplayer/ImageDisplayer";
import type { ImageLink } from "../../types";
import type { BowPicture } from "../../types/apiTypes";
import { getImageLinksForModel } from "../../utils/apiCalls";

interface ModelDisplayProps {
  modelId: number;
  sampleId?: number;
}

const ModelDisplay: React.FC<ModelDisplayProps> = ({ modelId, sampleId }) => {
  const [imageLinks, setImageLinks] = useState<BowPicture[]>([]);
  const [submodels, setSubModels] = useState<string[]>([]);

  useEffect(() => {
    const fetcher = async () => {
      const fetchedImageLinks = await getImageLinksForModel(modelId);
      setImageLinks(fetchedImageLinks);
    };
    fetcher();
  }, []);
  if (imageLinks.length === 0) {
    return <div>No images found!</div>;
  }
  return (
    <div style={{ padding: "5px" }}>
      <ImageDisplayer
        title="All Images"
        images={imageLinks.map(el => {
          return { pictureLink: el.pictureLink, caption: el.caption };
        })}
      />
      {submodels.map(submodel => {
        return (
          <ImageDisplayer
            title={`Submodel: ${submodel !== "" ? submodel : "Regular"}`}
            images={imageLinks.filter(el => {
              return el.submodel === submodel;
            })}
          />
        );
      })}
      {sampleId !== -1 &&
        imageLinks.filter(el => {
          return el.sampleId === sampleId;
        }).length > 0 && (
          <ImageDisplayer
            title={`Sample: ${sampleId}`}
            images={imageLinks.filter(el => {
              return el.sampleId === sampleId;
            })}
          />
        )}
    </div>
  );
};

ModelDisplay.defaultProps = {
  sampleId: -1,
};
export default ModelDisplay;
