/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  Grid,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Typography,
} from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";
import DataTable from "react-data-table-component-with-filter";
import useTheme from "@mui/material/styles/useTheme.js";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BowColumns from "./BowColumns";
import { MIN_SCREEN_WIDTH } from "../../constants";
import type { Sample } from "../../types/apiTypes";
import type { OptionType } from "../../types";
import "./BowColumns.css";

interface SampleWithTitle {
  sample: Sample;
  title: string;
}
interface ComparativeTableProps {
  samples: SampleWithTitle[];
}

const ComparativeTable: React.FC<ComparativeTableProps> = ({ samples }) => {
  const sortedColumns = BowColumns.sort((a: OptionType, b: OptionType) => {
    return a.label.localeCompare(b.label);
  });
  const defaultColumns = [
    {
      label: "Arrow Pass Width",
      value: "arrow_pass_width",
      extraProps: {
        name: "Arrow Pass Width (mm)",
        sortable: true,
        filterable: true,
        wrap: true,
        reorder: true,
        selector: (row: { sample: Sample }) => {
          return row.sample.arrowPassWidth !== -1
            ? row.sample.arrowPassWidth
            : "N/A";
        },
      },
    },
    {
      label: "Strung Length",
      value: "strungLength",
      extraProps: {
        name: "Strung Length (cm)",
        sortable: true,
        filterable: true,
        wrap: true,
        reorder: true,
        selector: (row: { sample: Sample }) => {
          if (row.sample.strungLength !== -1) {
            return row.sample.strungLength;
          }
          return "N/A";
        },
      },
    },
    {
      label: "Bow Mass",
      value: "bow_mass",
      extraProps: {
        name: "Bow Mass (g)",
        sortable: true,
        filterable: true,
        wrap: true,
        reorder: true,
        selector: (row: { sample: Sample }) => {
          if (row.sample.bowMass !== -1) {
            return row.sample.bowMass;
          }
          return "N/A";
        },
      },
    },
    {
      label: "Unstrung Length",
      value: "unstrung_length",
      extraProps: {
        name: "Unstrung Length (cm)",
        sortable: true,
        filterable: true,
        wrap: true,
        reorder: true,
        selector: (row: { sample: Sample }) => {
          if (row.sample.unstrungLength !== -1) {
            return row.sample.unstrungLength;
          }
          return "N/A";
        },
      },
    },
    {
      label: "Max Draw Length",
      value: "max_draw_length",
      extraProps: {
        name: "Max Draw Length (inches)",
        sortable: true,
        filterable: true,
        wrap: true,
        reorder: true,
        selector: (row: { sample: Sample }) => {
          return row.sample.maxDraw !== -1 ? row.sample.maxDraw : "N/A";
        },
      },
    },
    {
      label: "Grip Depth",
      value: "grip_depth",
      extraProps: {
        name: "Grip Depth (mm)",
        sortable: true,
        filterable: true,
        wrap: true,
        reorder: true,
        selector: (row: { sample: Sample }) => {
          if (row.sample.gripDepth !== -1) {
            return row.sample.gripDepth;
          }
          return "N/A";
        },
      },
    },
    {
      label: "Grip Width",
      value: "grip_width",
      extraProps: {
        name: "Grip width (mm)",
        sortable: true,
        filterable: true,
        wrap: true,
        reorder: true,
        selector: (row: { sample: Sample }) => {
          if (row.sample.gripWidth !== -1) {
            return row.sample.gripWidth;
          }
          return "N/A";
        },
      },
    },
  ] as OptionType[];
  const screenIsMinWidth = useMediaQuery(`(min-width:${MIN_SCREEN_WIDTH}px)`);
  const theme = useTheme();
  const [selectedColumns, setSelectedColumns] =
    useState<OptionType[]>(defaultColumns);

  return (
    <div className="custom-datatable-container">
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        sx={{ my: 2 }}
        disableGutters
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Compare bow measurements</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {sortedColumns.map((column: OptionType) => {
              return (
                <Grid item xs={screenIsMinWidth ? 3 : 6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          selectedColumns.find(item => {
                            return item.label === column.label;
                          }) !== undefined
                        }
                      />
                    }
                    label={column.label}
                    onChange={(e, v) => {
                      if (v === true) {
                        setSelectedColumns([...selectedColumns, column]);
                      } else {
                        setSelectedColumns(
                          selectedColumns.filter(selectedColumn => {
                            return selectedColumn.label !== column.label;
                          })
                        );
                      }
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
          <DataTable
            title="Bow Measurements"
            columns={[
              {
                name: "Title",
                sortable: true,
                filterable: true,
                wrap: true,
                reorder: true,
                selector: (row: { title: string }) => {
                  return row.title;
                },
              },
              ...selectedColumns.map(column => {
                return column.extraProps;
              }),
            ]}
            theme={theme.palette.mode}
            striped
            fixedHeader
            data={samples}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ComparativeTable;
