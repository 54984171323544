const shaftDataColumns = [
  {
    name: "Manufacturer",
    sortable: true,
    filterable: true,
    wrap: true,
    width: "150px",
    selector: (row: { manufacturer: string }) => {
      return row.manufacturer;
    },
  },
  {
    name: "Model",
    sortable: true,
    filterable: true,
    wrap: true,
    width: "225px",
    selector: (row: { model: string }) => {
      return row.model;
    },
  },
  {
    name: "Spine",
    sortable: true,
    filterable: true,
    reorder: true,
    width: "75px",
    selector: (row: { spine: string }) => {
      return row.spine;
    },
  },
  {
    name: "GPI",
    sortable: true,
    reorder: true,
    filterable: true,
    width: "75px",
    selector: (row: { gpi: string }) => {
      return row.gpi;
    },
    format: (row: { gpi: string | number }) => {
      if (row.gpi === "" || row.gpi === -1) {
        return "N/A";
      }
      return row.gpi;
    },
  },
  {
    name: "OD",
    sortable: true,
    filterable: true,
    reorder: true,
    width: "75px",
    selector: (row: { od: string }) => {
      return row.od;
    },
    format: (row: { od: string | number }) => {
      if (row.od === "" || row.od === -1) {
        return "N/A";
      }
      return row.od;
    },
  },
  {
    name: "ID",
    sortable: true,
    filterable: true,
    reorder: true,
    width: "75px",
    selector: (row: { id: string }) => {
      return row.id;
    },
    format: (row: { id: string | number }) => {
      if (row.id === "" || row.id === -1) {
        return "N/A";
      }
      return row.id;
    },
  },
  {
    name: "Stock Length (in)",
    sortable: true,
    filterable: true,
    wrap: true,
    reorder: true,
    width: "120px",
    selector: (row: { stockLength: string }) => {
      return row.stockLength;
    },
    format: (row: { stockLength: string | number }) => {
      if (row.stockLength === "" || row.stockLength === -1) {
        return "N/A";
      }
      return row.stockLength;
    },
  },
  {
    name: "Insert Stem Length (in)",
    sortable: true,
    filterable: true,
    wrap: true,
    reorder: true,
    selector: (row: { insertStemLength: string }) => {
      return row.insertStemLength;
    },
    format: (row: { insertStemLength: string | number }) => {
      if (row.insertStemLength === "" || row.insertStemLength === -1) {
        return "N/A";
      }
      return row.insertStemLength;
    },
  },
  {
    name: "Bushing Nock Inner Length (in)",
    sortable: true,
    filterable: true,
    wrap: true,
    reorder: true,
    selector: (row: { bushingNockInnerLength: string }) => {
      return row.bushingNockInnerLength;
    },
    format: (row: { bushingNockInnerLength: string | number }) => {
      if (
        row.bushingNockInnerLength === "" ||
        row.bushingNockInnerLength === -1
      ) {
        return "N/A";
      }
      return row.bushingNockInnerLength;
    },
  },
  {
    name: "Bushing Outer Length (in)",
    sortable: true,
    filterable: true,
    wrap: true,
    reorder: true,
    selector: (row: { bushingOuterLength: string }) => {
      return row.bushingOuterLength;
    },
    format: (row: { bushingOuterLength: string | number }) => {
      if (row.bushingOuterLength === "" || row.bushingOuterLength === -1) {
        return "N/A";
      }
      return row.bushingOuterLength;
    },
  },
  {
    name: "Insert Weight (grain)",
    sortable: true,
    filterable: true,
    wrap: true,
    reorder: true,
    width: "150px",
    selector: (row: { insertWeight: string }) => {
      return row.insertWeight;
    },
    format: (row: { insertWeight: string | number }) => {
      if (row.insertWeight === "" || row.insertWeight === -1) {
        return "N/A";
      }
      return row.insertWeight;
    },
  },
];

export default shaftDataColumns;
