import React, { useEffect, useRef } from "react";
import { Chart, registerables } from "chart.js";
import {
  BoxPlotController,
  BoxAndWiskers,
} from "@sgratzl/chartjs-chart-boxplot";

Chart.register(...registerables);
Chart.register(BoxPlotController, BoxAndWiskers);

type ChartData = {
  labels?: string[];
  datasets: {
    label?: string;
    data: number[] | number[][];
    backgroundColor?: string;
    borderColor?: string;
    borderWidth?: number;
  }[];
};

type ChartOptions = {
  responsive?: boolean;
  scales?: {
    x?: {
      beginAtZero?: boolean;
      title?: {
        display?: boolean;
        text?: string;
      };
    };
  };
  plugins?: {
    legend?: {
      display?: boolean;
      position?: "top" | "left" | "bottom" | "right";
    };
    tooltip?: {
      enabled?: boolean;
    };
  };
};

interface BoxPlotProps {
  data: ChartData;
  options: ChartOptions;
}

const BoxPlot: React.FC<BoxPlotProps> = ({ data, options }) => {
  const canvasRef = useRef(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (canvasRef.current) {
      const chart = new Chart(canvasRef.current, {
        type: "boxplot",
        data,
        options,
      });

      return () => {
        chart.destroy();
      };
    }
  }, [data, options]);

  return <canvas ref={canvasRef} />;
};

export default BoxPlot;
