/* eslint-disable no-unused-vars */
import axios from "axios";

import API_URL from "../constants";

import type {
  BowType,
  BowTypeTag,
  Tag,
  SampleSummary,
  RawSample,
  Sample,
  FpsDataPoint,
  DataUpdate,
  BowPicture,
  ComparisonSample,
  Shaft,
  Collar,
  ShaftData,
  CollarData,
  FPSDataPoint,
  TorsionPoint,
} from "../types/apiTypes";

import type { Point } from "../types";

export const parsePath = (path: string): [number, number][] => {
  const regex = /\(([\d.]+),([\d.]+)\)/g;
  return Array.from(path.matchAll(regex), match => {
    return [parseFloat(match[1]), parseFloat(match[2])];
  });
};

export const getBowsWithTags = async () => {
  try {
    const bowResponse = await axios.get(`${API_URL}/bow-types`, {
      timeout: 10000,
    });
    const bowTypeTagResponse = await axios.get(`${API_URL}/bow-type-tags/`, {
      timeout: 1000000,
    });
    const bows = (await bowResponse.data) as BowType[];
    const bowTypeTags = (await bowTypeTagResponse.data) as BowTypeTag[];

    const bowTypesWithTags = bows.map((bow: BowType) => {
      const relatedTags = bowTypeTags.filter((tag: BowTypeTag) => {
        return tag.bowTypeId === bow.bowTypeId;
      });
      const tagIds = relatedTags.map(tag => {
        return tag.tagId;
      });
      return { ...bow, tags: tagIds };
    });
    return bowTypesWithTags;
  } catch (e) {
    return [];
  }
};

export const getTags = async () => {
  try {
    const tags = await axios.get(`${API_URL}/tags`, {
      timeout: 10000,
    });

    const tagData = await tags.data;

    return tagData as Tag[];
  } catch (e) {
    return [];
  }
};

export const getTagsForBow = async (bowTypeId: number) => {
  try {
    const tags = await axios.get(
      `${API_URL}/bow-type-tags/bow-type/${bowTypeId}`,
      {
        timeout: 10000,
      }
    );

    const tagData = await tags.data;

    return tagData as BowTypeTag[];
  } catch (e) {
    return [];
  }
};

export const getSamplesForBowType = async (bowTypeId: number) => {
  try {
    const samplesForBowType = await axios.get(
      `${API_URL}/samples/bow-type/${bowTypeId}`,
      {
        timeout: 10000,
      }
    );
    const samples = (await samplesForBowType.data) as SampleSummary[];
    return samples;
  } catch (e) {
    return [];
  }
};

export const getSample = async (sampleId: number) => {
  try {
    const samplesForBowType = await axios.get(
      `${API_URL}/samples/${sampleId}`,
      {
        timeout: 10000,
      }
    );
    const sampleData = (await samplesForBowType.data) as RawSample;
    return {
      ...sampleData,
      dfData: parsePath(sampleData.dfData),
      centralDifferences: parsePath(sampleData.centralDifferences),
      regressionCurve: parsePath(sampleData.regressionCurve),
      regressionDerivativeValues: parsePath(
        sampleData.regressionDerivativeValues
      ),
    } as Sample;
  } catch (e) {
    return [];
  }
};

export const getFpsDataForSample = async (sampleId: number) => {
  try {
    const fpsResponse = await axios.get(`${API_URL}/fps-data/${sampleId}`, {
      timeout: 10000,
    });
    const fpsData = (await fpsResponse.data) as FpsDataPoint[];
    return fpsData;
  } catch (e) {
    return [];
  }
};

export const getDataUpdates = async (page: number = 0) => {
  const pageSize = 25;
  try {
    const filter = {
      offset: page * pageSize,
      limit: pageSize,
      order: "modificationDate DESC",
    };

    const filterParam = encodeURIComponent(JSON.stringify(filter));

    const fpsResponse = await axios.get(
      `${API_URL}/data-updates?filter=${filterParam}`,
      {
        timeout: 10000,
      }
    );
    const dataUpdates = (await fpsResponse.data) as DataUpdate[];
    return dataUpdates;
  } catch (e) {
    return [];
  }
};

export const getImageLinksForModel = async (bowTypeId: number) => {
  try {
    const imageResponse = await axios.get(
      `${API_URL}/bow-pictures/bow_type_id/${bowTypeId}`,
      {
        timeout: 10000,
      }
    );
    const images = (await imageResponse.data) as BowPicture[];
    return images;
  } catch (e) {
    return [];
  }
};

export const getComparisonSamples = async () => {
  try {
    const imageResponse = await axios.get(`${API_URL}/samples/all`, {
      timeout: 10000,
    });
    const images = (await imageResponse.data) as ComparisonSample[];
    return images;
  } catch (e) {
    return [];
  }
};

export const getShafts = async () => {
  try {
    const shaftResponse = await axios.get(`${API_URL}/arrow-shafts`, {
      timeout: 10000,
    });
    const images = (await shaftResponse.data) as Shaft[];
    return images;
  } catch (e) {
    return [];
  }
};

export const getCollars = async () => {
  try {
    const shaftResponse = await axios.get(`${API_URL}/shaft-collars`, {
      timeout: 10000,
    });
    const images = (await shaftResponse.data) as Collar[];
    return images;
  } catch (e) {
    return [];
  }
};

export const login = async (username: string, password: string) => {
  const requestData = {
    email: username,
    password,
  };
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post(
    `${API_URL}/users/login`,
    requestData,
    requestOptions
  );
  return response;
};

export const createSample = async (sample: Partial<Sample>) => {
  const requestData = sample;
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: document.cookie,
    },
  };

  const response = await axios.post(
    `${API_URL}/samples`,
    requestData,
    requestOptions
  );

  return response;
};

export const createTorsionPoints = async (
  torsionData: Point[],
  sampleId: number
) => {
  const requestData = torsionData.map((point: Point) => {
    return {
      sampleId,
      mass: point.x,
      deflection: point.y,
    };
  });
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: document.cookie,
    },
  };
  const response = await axios.post(
    `${API_URL}/torsional-stability-data`,
    requestData,
    requestOptions
  );
  return response;
};

export const updateSample = async (sample: Partial<Sample>) => {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: document.cookie,
    },
  };

  const response = await axios.patch(
    `${API_URL}/samples/${sample.sampleId}`,
    sample,
    requestOptions
  );

  return response;
};

export const updateShafts = async (shafts: ShaftData[]) => {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: document.cookie,
    },
  };

  const response = await axios.put(
    `${API_URL}/arrow-shafts`,
    shafts,
    requestOptions
  );

  return response;
};

export const updateCollars = async (collars: CollarData[]) => {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: document.cookie,
    },
  };

  const response = await axios.put(
    `${API_URL}/shaft-collars`,
    collars,
    requestOptions
  );

  return response;
};

export const updateFps = async (fpsData: FPSDataPoint[]) => {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: document.cookie,
    },
  };

  const response = await axios.put(
    `${API_URL}/fps-data`,
    fpsData,
    requestOptions
  );

  return response;
};

export const clearCache = async () => {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: document.cookie,
    },
  };
  await axios.post(`${API_URL}/cache/clear`, {}, requestOptions);
};

export const updateBowType = async (bowTypeId: number, data: Object) => {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: document.cookie,
    },
  };
  return axios.patch(
    `${API_URL}/bow-types/${bowTypeId}`,
    { bowTypeId, ...data },
    requestOptions
  );
};

export const addTagForBowType = async (bowTypeId: number, tagId: number) => {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: document.cookie,
    },
  };
  await axios.post(
    `${API_URL}/bow-type-tags`,
    {
      bowTypeId,
      tagId,
    },
    requestOptions
  );
};

export const addBowType = async (
  manufacturer: string,
  modelName: string,
  bowLink: string
) => {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: document.cookie,
    },
  };
  await axios.post(
    `${API_URL}/bow-types`,
    {
      manufacturer,
      modelName,
      bowLink,
    },
    requestOptions
  );
};

export const addTag = async (tagName: string, tagDescription: string) => {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: document.cookie,
    },
  };
  await axios.post(
    `${API_URL}/tags`,
    {
      tagName,
      tagDescription,
    },
    requestOptions
  );
};

export const getTorsionForSample = async (sampleId: number) => {
  const response = await axios.get(
    `${API_URL}/torsional-stability-data/${sampleId}`
  );
  return response.data as TorsionPoint[];
};
