import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button, Box } from "@mui/material";
import "./Header.css";

const Header = () => {
  const navigate = useNavigate();
  const [clicked, setClicked] = useState("single");
  return (
    <div className="header">
      <Box
        sx={{
          width: "100%",
          height: "auto",
          paddingTop: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <Typography variant="h2">Asiatic Bow Database</Typography>
        <Button
          sx={{
            mt: 1,
            mr: 0.5,
            backgroundColor: theme => {
              return clicked === "single"
                ? theme.palette.primary.dark
                : theme.palette.primary.light;
            },
          }}
          variant="contained"
          onClick={() => {
            setClicked("single");
            navigate("/");
          }}
        >
          <Typography>View Single Bow</Typography>
        </Button>
        <Button
          variant="contained"
          sx={{
            mt: 1,
            mx: 0.5,
            backgroundColor: theme => {
              return clicked === "compare"
                ? theme.palette.primary.dark
                : theme.palette.primary.light;
            },
          }}
          onClick={() => {
            setClicked("compare");
            navigate("/compare");
          }}
        >
          <Typography>Compare Bows</Typography>
        </Button>
        <Button
          sx={{
            mt: 1,
            mx: 0.5,
            backgroundColor: theme => {
              return clicked === "arrow"
                ? theme.palette.primary.dark
                : theme.palette.primary.light;
            },
          }}
          onClick={() => {
            setClicked("arrow");
            navigate("/arrows");
          }}
          variant="contained"
        >
          <Typography>Arrow Data</Typography>
        </Button>
        <Button
          sx={{
            mt: 1,
            mx: 0.5,
            backgroundColor: theme => {
              return clicked === "glossary"
                ? theme.palette.primary.dark
                : theme.palette.primary.light;
            },
          }}
          onClick={() => {
            setClicked("glossary");
            navigate("/glossary");
          }}
          variant="contained"
        >
          <Typography>Glossary</Typography>
        </Button>
        <Button
          sx={{
            mt: 1,
            mx: 0.5,
            backgroundColor: theme => {
              return clicked === "faq"
                ? theme.palette.primary.dark
                : theme.palette.primary.light;
            },
          }}
          onClick={() => {
            setClicked("faq");
            navigate("/faq");
          }}
          variant="contained"
        >
          <Typography>FAQ</Typography>
        </Button>
        <Button
          sx={{
            mt: 1,
            mx: 0.5,
            backgroundColor: theme => {
              return clicked === "contributing"
                ? theme.palette.primary.dark
                : theme.palette.primary.light;
            },
          }}
          onClick={() => {
            setClicked("contributing");
            navigate("/contributing");
          }}
          variant="contained"
        >
          <Typography>Contributing</Typography>
        </Button>
      </Box>
    </div>
  );
};

export default Header;
