import React, { useState, useEffect } from "react";
import { Button, Card, CardMedia, TextField, Stack } from "@mui/material";
import { remove } from "piexif-ts";
import { getSelectBowOptions } from "../../utils/utils";
import SearchBar from "../SearchBar/SearchBar";
import type { OptionType } from "../../types";
import SendToDbButton from "../SendToDbButton/SendToDbButton";
import { getSamplesForBowType } from "../../utils/apiCalls";
import type { SampleSummary } from "../../types/apiTypes";

const ImageUpload = () => {
  const [options, setOptions] = useState<OptionType[]>([]);
  const [sampleOptions, setSampleOptions] = useState<OptionType[]>([]);
  const [selectedBow, setSelectedBow] = useState<OptionType | null>(null);
  const [selectedSample, setSelectedSample] = useState<OptionType | null>(null);
  const [candidateImages, setCandidateImages] = useState<File[]>([]);
  const [imageDescriptions, setImageDescriptions] = useState<string[]>([]);
  const [dataToSend, setSendableData] = useState(new FormData());
  const [readyToSend, setReadyToSend] = useState(false);

  useEffect(() => {
    const fetcher = async () => {
      const fetchedBows = await getSelectBowOptions();
      setOptions(fetchedBows);
    };
    fetcher();
  }, []);

  useEffect(() => {
    const fetcher = async () => {
      if (selectedBow !== null) {
        const fetchedSamples = await getSamplesForBowType(
          parseFloat(selectedBow.value)
        );

        const sampleIdOptions = fetchedSamples.map((sample: SampleSummary) => {
          return {
            label: `Sample #${sample.sampleId}`,
            value: `${sample.sampleId}`,
          } as OptionType;
        });

        setSampleOptions(sampleIdOptions);
      }
    };
    fetcher();
  }, [selectedBow]);

  useEffect(() => {
    if (dataToSend.has("bowTypeId") && dataToSend.has("sampleId")) {
      setReadyToSend(true);
    }
  }, [dataToSend]);

  useEffect(() => {
    if (selectedSample && selectedBow) {
      const formData = new FormData();
      candidateImages.forEach((image, i) => {
        formData.append(`image_${i}`, image);

        if (imageDescriptions[i] !== undefined) {
          formData.append(`descriptions_${i}`, imageDescriptions[i]);
        } else {
          formData.append(`descriptions_${i}`, "");
        }
      });
      formData.append("bowTypeId", selectedBow.value);
      formData.append("sampleId", selectedSample.value);
      setSendableData(formData);
    }
  }, [selectedBow, selectedSample, imageDescriptions, candidateImages]);

  const handleSelectBow = (selectedOption: OptionType | null) => {
    setSelectedBow(selectedOption);
  };

  const handleSelectSample = (selectedOption: OptionType | null) => {
    if (selectedOption !== null) {
      setSelectedSample(selectedOption);
    }
  };

  function getBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        return resolve(reader.result);
      };
      reader.onerror = error => {
        return reject(error);
      };
    });
  }

  // Helper function to convert data URI to Blob
  function dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    /* eslint-disable no-plusplus */
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null && e.target.files.length !== 0) {
      const uploadedImages = Array.from(e.target.files);
      const modifiedImages = await Promise.all(
        uploadedImages.map(async (image: File) => {
          if (image.type === "image/jpeg") {
            const b64File = (await getBase64(image)) as string;
            const modifiedBase64 = remove(b64File); // Gives me a 64bit string
            const modifiedBlob = dataURItoBlob(modifiedBase64);
            const modifiedFile = new File([modifiedBlob], image.name, {
              type: "image/jpeg",
            });
            return modifiedFile;
          }
          return image;
        })
      );

      setCandidateImages([...modifiedImages]);
      setImageDescriptions(
        Array(modifiedImages.length).map(() => {
          return "";
        })
      );
    }
  };

  return (
    <Stack>
      <SearchBar
        name={options.length > 0 ? "Select a bow" : "Loading bow options"}
        onChange={handleSelectBow}
        options={options}
        forcedValue={selectedBow}
      />
      <SearchBar
        name={
          sampleOptions.length > 0
            ? "Select a sample"
            : "Loading sample options"
        }
        onChange={handleSelectSample}
        options={sampleOptions}
        forcedValue={selectedSample}
      />
      <Button variant="contained" component="label">
        Add images
        <input
          type="file"
          accept="image/*"
          multiple
          hidden
          onChange={handleFileUpload}
        />
      </Button>

      {candidateImages.length > 0 &&
        candidateImages.map((image, i: number) => {
          return (
            <Card
              sx={{ flexDirection: "column", margin: "auto", height: "60%" }}
            >
              <CardMedia
                component="img"
                height="600"
                image={URL.createObjectURL(image)}
                loading="lazy"
              />
              <TextField
                id="outlined-basic"
                label="Description"
                value={imageDescriptions[i]}
                onChange={e => {
                  imageDescriptions[i] = e.target.value;
                  setImageDescriptions([...imageDescriptions]);
                }}
              />
            </Card>
          );
        })}

      {readyToSend && (
        <SendToDbButton
          data={dataToSend}
          endpoint="bow-pictures"
          successMessage="uploaded pictures"
          form
        />
      )}
    </Stack>
  );
};

export default ImageUpload;
