/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import SearchBar from "../SearchBar/SearchBar";
import { getSelectTagOptions } from "../../utils/utils";
import type { OptionType } from "../../types";
import SendToDbButton from "../SendToDbButton/SendToDbButton";
import { addTag } from "../../utils/apiCalls";

const AddTag = () => {
  const [options, setOptions] = useState<OptionType[]>([]);

  const [selectedTag, setSelectedTag] = useState<OptionType | null>(null);
  const [newTag, setNewTag] = useState("");
  const [newTagDescriptor, setNewTagDescriptor] = useState("");

  useEffect(() => {
    const fetcher = async () => {
      const bowOptions = await getSelectTagOptions();
      setOptions(bowOptions);
    };
    fetcher();
  }, []);

  const handleSelectChange = (selectedOption: OptionType | null) => {
    setSelectedTag(selectedOption);
  };

  const sendToDb = (data: { tagName: string; tagDescriptor: string }) => {
    return addTag(data.tagName, data.tagDescriptor);
  };
  return (
    <div>
      <SearchBar
        name={options.length > 0 ? "Existing tags" : "Loading tag options"}
        onChange={handleSelectChange}
        options={options}
      />
      <div>
        Add New Tag
        <div>
          Tag name:{" "}
          <input
            type="text"
            value={newTag}
            onChange={e => {
              return setNewTag(e.target.value);
            }}
          />
        </div>
      </div>

      <div>
        New tag descriptor :{" "}
        <input
          type="text"
          value={newTagDescriptor}
          onChange={e => {
            return setNewTagDescriptor(e.target.value);
          }}
        />
      </div>
      <SendToDbButton
        data={{ tagName: newTag, tagDescriptor: newTagDescriptor }}
        f={sendToDb}
        endpoint="api/tags"
        successMessage={`Successfully added new tag ${newTag}`}
      />
    </div>
  );
};

export default AddTag;
