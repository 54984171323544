/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Accordion,
  Box,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Card,
  Modal,
  CardHeader,
  CardMedia,
  CardActionArea,
  Pagination,
  Stack,
} from "@mui/material";
import TopBarProgress from "react-topbar-progress-indicator";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import type { BowPicture } from "../../../types/apiTypes";
import type { DisplayableImage } from "../../../types/index";

interface ImageDisplayerProps {
  title: string;
  images: DisplayableImage[];
}

interface SingleImageProps {
  link: string;
  caption: string;
}

const SingleImage: React.FC<SingleImageProps> = ({ link, caption }) => {
  const [open, setOpen] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setLoaded(true);
  }, []);

  if (!loaded) {
    return <TopBarProgress />;
  }
  return (
    <div>
      <Card sx={{ flexDirection: "column", margin: "auto", height: "60%" }}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="600"
            image={link}
            loading="lazy"
            onClick={handleOpen}
          />
          <CardHeader
            title={caption}
            sx={{ height: "2vw", textAlign: "center" }}
          />
        </CardActionArea>
      </Card>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
          component="img"
          maxHeight="90%"
          maxWidth="90%"
          src={link}
          loading="lazy"
        />
      </Modal>
    </div>
  );
};

const ImageDisplayer: React.FC<ImageDisplayerProps> = ({ title, images }) => {
  const [visibleImageIndex, setVisibleImageIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e: any) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: any) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      setVisibleImageIndex((visibleImageIndex - 1) % images.length);
    }
    if (isRightSwipe) {
      setVisibleImageIndex((visibleImageIndex + 1) % images.length);
    }
  };

  const handlePaginate = (e: any, v: any) => {
    setVisibleImageIndex((v - 1) % images.length);
  };

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      sx={{ paddingBottom: "5px", marginY: "5px" }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          alignItems="center"
          spacing={2}
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
        >
          <SingleImage
            link={
              images[Math.abs(visibleImageIndex % images.length)].pictureLink
            }
            caption={
              images[Math.abs(visibleImageIndex % images.length)].caption
            }
          />
          <Pagination
            count={images.length}
            siblingCount={0}
            boundaryCount={2}
            page={visibleImageIndex + 1}
            onChange={handlePaginate}
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default ImageDisplayer;
