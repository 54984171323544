import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Grid, Typography, Link } from "@mui/material";

const Footer = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
    >
      <Grid container direction="column" alignItems="left">
        <Grid item xs={12}>
          <Typography
            variant="h5"
            sx={{
              color: theme => {
                return theme.palette.text.secondary;
              },
            }}
          >
            Asiatic Bow Database
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            sx={{
              color: theme => {
                return theme.palette.text.secondary;
              },
            }}
          >
            {`${new Date().getFullYear()} | Questions? Email me at `}
            <Link href="mailto:asiaticbowdatabase@gmail.com">
              asiaticbowdatabase@gmail
            </Link>
            {` | Check out the `}
            <Link to="/faq" component={RouterLink}>
              FAQ
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            sx={{
              color: theme => {
                return theme.palette.text.secondary;
              },
            }}
          >
            Support this project{" "}
            <Link href="https://ko-fi.com/notliria">by donating here</Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
