/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useTheme from "@mui/material/styles/useTheme";
import Collapsible from "react-collapsible";
import collarDataColumns from "./Columns";
import "./CollarTable.css";
import type { Collar } from "../../types/apiTypes";

interface CollarTableProps {
  data: Collar[];
}

const CollarTable: React.FC<CollarTableProps> = ({ data }) => {
  const theme = useTheme();
  const [collarData, setCollarData] = useState<Collar[]>(data);

  const [selectedRows, setSelectedRows] = useState<Collar[]>([]);

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  useEffect(() => {
    const updatedShaftData = [
      ...selectedRows,
      ...collarData.filter(row => {
        return !selectedRows.includes(row);
      }),
    ];
    setCollarData(updatedShaftData);
  }, [selectedRows]);

  const customSort = (rows: any[], selector: any, direction: string) => {
    return rows.sort((rowA, rowB) => {
      // use the selector function to resolve your field names by passing the sort comparitors
      const aField = selector(rowA) === "unknown" ? 0 : selector(rowA);
      const bField = selector(rowB) === "unknown" ? 0 : selector(rowB);

      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }

      return direction === "desc" ? comparison * -1 : comparison;
    });
  };

  return (
    <div className="custom-collar-datatable-container">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <Typography color={theme.palette.primary.contrastText}>
            Collar data
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DataTable
            title="Collar Data"
            columns={collarDataColumns}
            data={collarData}
            selectableRows
            theme={theme.palette.mode}
            keyField="collarId"
            sortFunction={customSort}
            onSelectedRowsChange={handleRowSelected}
            fixedHeader
            pagination
            paginationPerPage={25}
            striped
            paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CollarTable;
