/* eslint-disable no-console */
import React, { useState, useEffect, CSSProperties } from "react";
import { useCSVReader } from "react-papaparse";
import SearchBar from "../SearchBar/SearchBar";
import type { BowData, EditableSample, OptionType, Point } from "../../types";
import processBowCsv, {
  getSelectBowOptions,
  SampleFields,
} from "../../utils/utils";
import DataEditor from "../DataEditor/DataEditor";
import SendToDbButton from "../SendToDbButton/SendToDbButton";
import SingleBowGraph from "../SingleBowGraph/SingleBowGraph";
import type { Sample } from "../../types/apiTypes";
import { createSample, createTorsionPoints } from "../../utils/apiCalls";

const styles = {
  csvReader: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
  } as CSSProperties,
  browseFile: {
    width: "20%",
  } as CSSProperties,
  acceptedFile: {
    border: "1px solid #ccc",
    height: 45,
    lineHeight: 2.5,
    paddingLeft: 10,
    width: "80%",
  } as CSSProperties,
  remove: {
    borderRadius: 0,
    padding: "0 20px",
  } as CSSProperties,
  progressBarBackgroundColor: {
    backgroundColor: "red",
  } as CSSProperties,
};

interface DataPackage extends EditableSample {
  bowTypeId: number;
}

const UploadSample = () => {
  const [options, setOptions] = useState<OptionType[]>([]);
  const [selectedBow, setSelectedBow] = useState<OptionType | null>(null);
  const [csvData, setCsvData] = useState();
  const [editedDfData, setEditedDfData] = useState<BowData | null>(null);
  const [writeData, setWriteData] = useState<EditableSample | Object>({});
  const { CSVReader } = useCSVReader();

  const handleFileUpload = (data: any) => {
    setCsvData(data.data);
    const processedCsv = processBowCsv(data.data) as EditableSample;
    processedCsv.dfData = JSON.stringify(processedCsv.dfData);
    processedCsv.torsionData = JSON.stringify(processedCsv.torsionData);
    setWriteData(processedCsv);
  };

  const handleDataChange = (newData: any) => {
    setWriteData(newData);
    try {
      setEditedDfData({
        regression_curve: [],
        regression_derivative_values: [],
        df_data: JSON.parse(newData.dfData).map((point: Point) => {
          return [point.x, point.y];
        }),
        central_differences: [],
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    const fetcher = async () => {
      const fetchedBows = await getSelectBowOptions();
      setOptions(fetchedBows);
    };
    fetcher();
  }, []);

  const handleSelectChange = (selectedOption: OptionType | null) => {
    setSelectedBow(selectedOption);
  };

  const prepForSend = (
    dataToWrite: EditableSample | Object,
    bowType: OptionType | null
  ) => {
    let sendData;
    if (dataToWrite !== null) {
      sendData = Object.keys(dataToWrite).reduce((acc: any, cur) => {
        if (
          (dataToWrite as EditableSample)[cur as keyof EditableSample] !== ""
        ) {
          acc[cur] = (dataToWrite as EditableSample)[
            cur as keyof EditableSample
          ];
        }
        return acc;
      }, {});
    }
    return { ...sendData, bowTypeId: bowType?.value };
  };

  const sendToDb = async (data: DataPackage) => {
    // We need to parse the data into 2 sections
    // first section is the sample itself
    // second section is the torsion data
    // So let's split it out

    const sampleData: Partial<Sample> = {};
    Object.keys(data).forEach(key => {
      if (SampleFields.includes(key)) {
        sampleData[key as keyof Sample] = data[key as keyof EditableSample];
      }
    });
    if (sampleData.bowTypeId !== undefined) {
      sampleData.bowTypeId = parseFloat(sampleData.bowTypeId.toString());
    }

    const sampleResponse = await createSample(sampleData);
    if (sampleResponse.status !== 200) {
      console.error(`Failed to upload to DB`);
      console.error(sampleResponse);
    } else {
      const torsionResponse = await createTorsionPoints(
        JSON.parse(data.torsionData),
        sampleResponse.data.sampleId
      );

      // eslint-disable-next-line no-console
      console.log(torsionResponse);
    }
    return sampleResponse;
  };

  return (
    <div>
      <CSVReader
        onUploadAccepted={(results: any) => {
          handleFileUpload(results);
        }}
        onDrop={(results: any) => {
          handleFileUpload(results);
        }}
        config={{ header: true }}
      >
        {({
          getRootProps,
          acceptedFile,
          ProgressBar,
          getRemoveFileProps,
        }: any) => {
          return (
            <>
              <div style={styles.csvReader}>
                <button
                  type="button"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...getRootProps()}
                  style={styles.browseFile}
                >
                  Browse file
                </button>
                <div style={styles.acceptedFile}>
                  {acceptedFile && acceptedFile.name}
                </div>
                <button
                  type="button"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...getRemoveFileProps()}
                  style={styles.remove}
                >
                  Remove
                </button>
              </div>
              <ProgressBar style={styles.progressBarBackgroundColor} />
            </>
          );
        }}
      </CSVReader>
      <div>
        {csvData !== undefined && (
          <div>
            <div>
              <SearchBar
                name={
                  options.length > 0 ? "Select a bow" : "Loading bow options"
                }
                onChange={handleSelectChange}
                options={options}
              />
            </div>
            <div>
              <DataEditor
                targetData={writeData}
                setTargetData={handleDataChange}
              />
            </div>
            <div>
              {editedDfData !== null && (
                <div>
                  <SingleBowGraph bowData={editedDfData} />
                </div>
              )}
            </div>
            <div>
              <SendToDbButton
                data={prepForSend(writeData, selectedBow)}
                endpoint="api/sample/new"
                successMessage="sent to db!"
                f={sendToDb}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadSample;
