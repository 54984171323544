import React, { useState, CSSProperties, useEffect } from "react";
import { useCSVReader } from "react-papaparse";
import { Button } from "@mui/material/";
import SendToDbButton from "../SendToDbButton/SendToDbButton";
import SearchBar from "../SearchBar/SearchBar";
import type { OptionType } from "../../types";
import { getSelectBowOptions } from "../../utils/utils";
import {
  getSample,
  getSamplesForBowType,
  updateFps,
} from "../../utils/apiCalls";
// eslint-disable-next-line no-unused-vars
import type { SampleSummary, Sample, FPSDataPoint } from "../../types/apiTypes";
import { calcEnergyAtPoint } from "../../utils/math";

const styles = {
  csvReader: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
  } as CSSProperties,
  browseFile: {
    width: "20%",
  } as CSSProperties,
  acceptedFile: {
    border: "1px solid #ccc",
    height: 45,
    lineHeight: 2.5,
    paddingLeft: 10,
    width: "80%",
  } as CSSProperties,
  remove: {
    borderRadius: 0,
    padding: "0 20px",
  } as CSSProperties,
  progressBarBackgroundColor: {
    backgroundColor: "red",
  } as CSSProperties,
};

interface SampleData {
  sample: Sample; // replace with actual type
  fpsData: number[]; // replace with actual type
  fpsRegressionData?: any; // replace with actual type
}

interface CsvData {
  data: [];
  errors: [];
  meta: [];
}

function findLongestSubsequence(data: FPSDataPoint[]) {
  // Parse FPS as numbers and sort the array
  data.sort((a, b) => {
    return a.fps - b.fps;
  });

  let longestSubsequence: FPSDataPoint[] = [];
  let tempSubsequence = [data[0]];

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < data.length; i++) {
    // Check if the current FPS is within the deviation from the first element of the temp subsequence
    if (Math.abs(data[i].fps - tempSubsequence[0].fps) <= 1) {
      tempSubsequence.push(data[i]);
    } else {
      // Check if the current tempSubsequence is the longest
      if (tempSubsequence.length > longestSubsequence.length) {
        longestSubsequence = tempSubsequence;
      }
      tempSubsequence = [data[i]];
    }
  }

  // Final check in case the longest subsequence is at the end of the array
  if (tempSubsequence.length > longestSubsequence.length) {
    longestSubsequence = tempSubsequence;
  }

  return longestSubsequence;
}

const AddFpsData = () => {
  const [options, setOptions] = useState<OptionType[]>([]);
  const [selectedBow, setSelectedBow] = useState<OptionType | null>(null);
  const [samples, setSamples] = useState<SampleSummary[]>([]);
  const [selectedSample, setSelectedSample] = useState<SampleData | null>(null);
  const [selectedButton, setSelectedButton] = useState<number | null>(null);
  const [fpsData, setFpsData] = useState<FPSDataPoint[]>([]);
  const { CSVReader } = useCSVReader();

  const processFpsCsv = (data: CsvData) => {
    const outputData = findLongestSubsequence(
      data.data
        .filter((row: any) => {
          return row["Measured FPS"] !== "";
        })
        .map(row => {
          const dlToBelly =
            row["Draw Length"] - selectedSample!.sample.gripDepth / 25.4;
          const storedEnergy = calcEnergyAtPoint(
            selectedSample!.sample.coeffs,
            selectedSample!.sample.regressionCurve[0][0],
            dlToBelly
          );
          // eslint-disable-next-line dot-notation
          const measuredEnergy = parseFloat(row["KE"]);
          const efficiency = measuredEnergy / storedEnergy;
          return {
            sampleId: selectedSample!.sample.sampleId,
            dl: parseFloat(row["Draw Length"]),
            drawLengthToBelly: dlToBelly,
            efficiency,
            measuredEnergy,
            storedEnergy,
            fps: parseFloat(row["Measured FPS"]),
            gpp: parseFloat(row["Actual GPP"]),
            arrowWeight: parseFloat(row["Actual Arrow Weight"]),
          } as FPSDataPoint;
        })
    );
    return outputData;
  };

  const handleFileUpload = (data: any) => {
    const outputData = processFpsCsv(data as CsvData);
    setFpsData(outputData);
  };

  useEffect(() => {
    const fetcher = async () => {
      const fetchedBows = await getSelectBowOptions();
      setOptions(fetchedBows);
    };
    fetcher();
  }, []);

  const sendToDb = (data: FPSDataPoint[]) => {
    return updateFps(data);
  };

  const handleSelectChange = async (selectedOption: OptionType | null) => {
    if (selectedOption) {
      setSelectedBow(selectedOption);
      try {
        const response = await getSamplesForBowType(
          parseFloat(selectedOption.value)
        );
        setSamples(response);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(
          `Failed to fetch samples for bow ${selectedOption.value}`,
          error
        );
      }
    }
  };

  const handleButtonClick = async (sampleId: number) => {
    setSelectedButton(sampleId);
    try {
      const sample = (await getSample(sampleId)) as Sample;
      setSelectedSample({ sample, fpsData: [] });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.debug(`Failed to fetch sample`);
    }
  };

  return (
    <div>
      <div>
        <SearchBar
          name={options.length > 0 ? "Select a bow" : "Loading bow options"}
          onChange={handleSelectChange}
          options={options}
          forcedValue={selectedBow}
        />
      </div>
      <div>
        {samples.map((sample: SampleSummary) => {
          return (
            <Button
              key={sample.sampleId}
              variant={
                selectedButton === sample.sampleId ? "contained" : "outlined"
              }
              onClick={() => {
                return handleButtonClick(sample.sampleId);
              }}
            >
              Sample {sample.sampleId}
            </Button>
          );
        })}
      </div>
      {selectedSample !== null && (
        <CSVReader
          onUploadAccepted={(results: any) => {
            handleFileUpload(results);
          }}
          onDrop={(results: any) => {
            handleFileUpload(results);
          }}
          config={{ header: true }}
        >
          {({
            getRootProps,
            acceptedFile,
            ProgressBar,
            getRemoveFileProps,
          }: any) => {
            return (
              <>
                <div style={styles.csvReader}>
                  <button
                    type="button"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...getRootProps()}
                    style={styles.browseFile}
                  >
                    Browse file
                  </button>
                  <div style={styles.acceptedFile}>
                    {acceptedFile && acceptedFile.name}
                  </div>
                  <button
                    type="button"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...getRemoveFileProps()}
                    style={styles.remove}
                  >
                    Remove
                  </button>
                </div>
                <ProgressBar style={styles.progressBarBackgroundColor} />
              </>
            );
          }}
        </CSVReader>
      )}
      {fpsData.length > 0 &&
        fpsData.map((row: FPSDataPoint) => {
          return <div>{JSON.stringify(row)}</div>;
        })}

      {fpsData.length > 0 && (
        <div>
          <SendToDbButton
            data={fpsData}
            f={sendToDb}
            endpoint="api/collars/new"
            successMessage="Added shafts!"
          />
        </div>
      )}
    </div>
  );
};
export default AddFpsData;
