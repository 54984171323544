const definitions = [
  {
    key: "Arrow Pass Width",
    description:
      "Width of the arrow pass at the narrowest point in millimetres",
  },
  {
    key: "Arrow Weight",
    description: "Mass of the arrow measured in grains",
  },
  {
    key: "Asym",
    description:
      "Whether or not a bow is visibly/noticeably assymetrical. Subjective measurement",
  },
  {
    key: "Bow Mass",
    description: "Mass of the bow in grams",
  },
  {
    key: "Brace Height",
    description:
      "Distance between the bow grip's deepest part and the bowstring, with the stock bowstring. Usually untuned. Measured in centimeters",
  },
  {
    key: "Central Differences",
    description:
      "Central difference of draw force data. This is a rough approximation of the first derivative of the draw force curve.",
  },
  {
    key: "Coefficients",
    description:
      "These are the regression coefficients used to estimate the regression curve and the derivative curve. See the regression curve for more information.",
  },
  {
    key: "DF Data",
    description:
      "Measured draw force data. Measured at every 1 inch between brace height and max draw length. This is the draw length to the belly of the bow, not to the back.",
  },
  {
    key: "Draw Length",
    description:
      "When FPS testing, we measure the draw length of the arrow to the back of the bow. This is that measured draw length.",
  },
  {
    key: "Draw Length to Belly",
    description: "Draw length minus the grip depth",
  },
  {
    key: "Efficiency",
    description:
      "Ratio of theoretical energy at a specific draw length to measured energy at that same draw length, where the measured energy is calculated using the kinetic energy of the arrow.",
  },
  { key: "FPS", description: "Arrow speed measured in feet per second." },
  { key: "GPP", description: "Ratio of arrow mass in grains to bow poundage" },
  {
    key: "Grip Depth",
    description:
      "Measurement of the grip depth, also referred to as grip thickness. Largest distance from the back of the bow to the belly of the bow.",
  },
  {
    key: "Grip Length",
    description: "Measurement of the grip length, from top to bottom",
  },
  {
    key: "Grip Width",
    description: "Measurement of the grip width, from side to side of the bow.",
  },
  {
    key: "Longbow point",
    description:
      "Calculated theoretical value. Suppose that we were to have a theoretical longbow whose draw force curve was a straight line between the first and last points of the bow's draw force curve.\n The longbow point would be the draw length at which that theoretical longbow's total energy surpasses that of the bow's measured energy. Not all bows have a longbow point. If the draw force curve of the bow is \\(f(x)\\), with initial points \\(a\\) and \\(b\\), then the theoretical longbow would have drawforce curve \\(g(x)\\), represented as a straight line between \\(a\\) and \\(b\\).",
  },
  {
    key: "Manufacture Date",
    description:
      "Year and month when the bow was manufactured, to the best of our knowledge",
  },
  { key: "Manufacturer", description: "Maker of the bow" },
  {
    key: "Max Draw",
    description: "Maximum draw length, as specified by the manufacturer",
  },
  {
    key: "Max/min Limb Thickness",
    description:
      "Maximum/minimum thickness of the bow, measured between the belly and the back of the bow, along the bending portion of the limb.",
  },

  {
    key: "Max/min Limb Width",
    description:
      "Maximum/minimum width of the bow, measuerd along the bending portion of the bow limb",
  },
  {
    key: "Measured Energy",
    description: "Measured kinetic energy of the arrow",
  },
  {
    key: "Min Box Depth",
    description:
      "Minimum depth of a box that could fit this bow. Essentially the greater of limb width or grip width",
  },
  {
    key: "Min Box Length",
    description: "Minimum length of a box that could fit this bow",
  },
  {
    key: "Min Box Width",
    description:
      "Minimum width of a box that could fit this bow. It can be seen as a proxy for the amount of reflex that a bow has",
  },

  {
    key: "Model Name",
    description: "Model name, what the manufacturer calls that bow",
  },
  {
    key: "Regression Curve",
    description:
      "Regression curve for the draw-force curve of the bow. We model this as a regression curve using the following model: \\(ae^{k_1(x - \\lambda_1)} - be^{-k_2(x - \\lambda_2)} + C\\). More details on the mathematics used to calculate this regression curve can be found here: https://math.stackexchange.com/questions/1428566/fit-sum-of-exponentials",
  },
  {
    key: "Regression Derivative",
    description:
      "First derivative of the regression of the draw force curve of the bow. Measures the change in poundage per inch (lb per inch) of the bow. Stacking is usually seen as a function of this curve.",
  },
  {
    key: "Siyah Effective Angle",
    description:
      "Effective angle of the siyah. This is measured by taking the tangent line of the bow limb where the bending section ends, drawing a line between the point where that tangent begins and the string nock ends, and taking the angle between those two lines.",
  },
  {
    key: "Siyah Effective Length",
    description:
      "Effective length of the siyah. This is measured by taking the tangent point where the bending section of the limb ends, and then meausuring a straight line between that and the nock",
  },
  {
    key: "Stock String Length Min/Max",
    description:
      "Measurement of the stock string, from end of loop to end of loop being the maximum, and from closest point along each loop the minimum",
  },
  {
    key: "Stored Energy",
    description:
      "Calculated energy stored in the bow, calculated as the integral of the regression of the draw force curve.",
  },
  {
    key: "Strung Length",
    description: "Strung length of the bow, measured from tip to tip",
  },
  {
    key: "Unstrung Length",
    description:
      "Unstrung contour length of the bow, measured from tip to tip along the belly curve",
  },
];

export default definitions;
