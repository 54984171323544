/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import {
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface ToggleableListItemProps {
  data: number[][];
  title: string;
  description: string;
}

const ToggleableListItem: React.FC<ToggleableListItemProps> = ({
  data,
  title,
  description,
}) => {
  const formatTable = (el: number[][]) => {
    return (
      <div className="tableContainer">
        <table
          style={{
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr>
              <th className="tableTitle">x</th>
              <th className="tableTitle">y</th>
            </tr>
          </thead>
          <tbody>
            {el.map((row: number[]) => {
              return (
                <tr key={row[0]}>
                  <td className="tdX">{row[0]}</td>
                  <td className="tdY">{row[1]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Tooltip title={description}>
            <Typography>{title}</Typography>
          </Tooltip>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ marginTop: 8, marginBottom: -8 }}>
            {formatTable(data)}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ToggleableListItem;
