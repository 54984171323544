/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  FormGroup,
  FormControlLabel,
  FormControl,
  Grid,
  Switch,
  Slider,
  Tooltip,
  Stack,
  Typography,
  TextField,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme.js";
import { Scatter } from "react-chartjs-2";
import Annotation from "chartjs-plugin-annotation";
// @ts-ignore
import ChartjsPluginWatermark from "chartjs-plugin-watermark";
import { Chart, LegendItem, registerables } from "chart.js";
import type { BowData } from "../../types/index.js";
import { calcEnergyAtPoint, calcDfAtDl } from "../../utils/math";
import { MIN_SCREEN_WIDTH, WATERMARK_SETTINGS } from "../../constants";

Chart.register(...registerables);
Chart.register(Annotation);
Chart.register(ChartjsPluginWatermark);

interface SingleBowGraphProps {
  bowData: BowData;
  regressionCoeffs?: number[];
  graphOptions?: SingleBowGraphOptions;
  gripDepth?: number;
}

interface SingleBowGraphOptions {
  displayDfData: boolean;
}

const SingleBowGraph: React.FC<SingleBowGraphProps> = ({
  bowData,
  graphOptions,
  regressionCoeffs,
  gripDepth,
}) => {
  const screenIsMinWidth = useMediaQuery(`(min-width:${MIN_SCREEN_WIDTH}px)`);
  const theme = useTheme();
  Chart.defaults.borderColor = theme.palette.grey["400"];
  const [showLineAt27, setShowLineAt27] = useState(false);
  const [showLineThroughFirstAndLast, setShowLineThroughFirstAndLast] =
    useState(false);
  const [showLbpiAsPercentage, setShowLbpiAsPercentage] = useState(false);
  const [showLineAt, setShowLineAt] = useState(27);
  const [showLineThrough, setShowLineThrough] = useState(
    bowData.df_data[bowData.df_data.length - 1][0]
  );
  const [regressionDerivativeValues, setRegressionDerivativeValues] = useState(
    bowData.regression_derivative_values
  );
  const [allowableXValues, setAllowableXValues] = useState<number[]>([]);
  const [pointThrough, setPointThrough] = useState(
    bowData.regression_curve.length === bowData.df_data.length
      ? bowData.regression_curve[bowData.df_data.length - 1]
      : [0, 0]
  );
  const [dl, setDl] = useState<number>(28);
  const [dfAt, setDfAt] = useState<number>(
    regressionCoeffs
      ? calcDfAtDl(
          regressionCoeffs,
          gripDepth !== undefined && gripDepth > 1 ? 28 - gripDepth : 28
        )
      : 0
  );

  useEffect(() => {
    if (regressionCoeffs) {
      setDfAt(
        calcDfAtDl(
          regressionCoeffs,
          gripDepth !== undefined && gripDepth > 1 ? dl - gripDepth / 25.4 : dl
        )
      );
    }
  }, [dl]);

  useEffect(() => {
    const xVals = bowData.df_data.map(point => {
      return point[0];
    });
    setAllowableXValues(xVals);
  }, [bowData]);

  useEffect(() => {
    if (showLbpiAsPercentage) {
      const yValues = bowData.regression_derivative_values.map(item => {
        return item[1];
      });
      const yMin = Math.min(...yValues);
      const yMax = Math.max(...yValues);
      const newValues = bowData.regression_derivative_values.map(cur => {
        return [cur[0], ((cur[1] - yMin) / (yMax - yMin)) * 10] as [
          number,
          number
        ];
      });
      setRegressionDerivativeValues(newValues);
    } else {
      setRegressionDerivativeValues(bowData.regression_derivative_values);
    }
  }, [showLbpiAsPercentage, bowData.regression_derivative_values]);

  const data = {
    datasets: [
      {
        label: "Regression DF Curve",
        data: bowData.regression_curve,
        showLine: true,
        tension: 0.5,
        borderWidth: 0.75,
        borderColor: theme.palette.primary.main,
        fill: false,
        backgroundColor: theme.palette.primary.main, // "rgba(255, 0, 0, 1)",
        yAxisID: "lbs",
      },
      {
        label: "Regression Derivative Values",
        data: regressionDerivativeValues,
        showLine: true,
        tension: 0.5,
        fill: false,
        borderWidth: 0.75,
        borderColor: theme.palette.secondary.dark,
        backgroundColor: theme.palette.secondary.dark, // "rgba(0, 0, 255, 1)",
        yAxisID: "lbpi",
      },
      {
        label: "Measured DF Data",
        data: bowData.df_data,
        showLine: true,
        borderWidth: 0.75,
        backgroundColor: "green",
        hidden: graphOptions?.displayDfData || true,
        yAxisID: "lbs",
      },
      {
        label: "Central Differences",
        data: bowData.central_differences,
        showLine: true,
        borderWidth: 0.75,
        backgroundColor: "purple",
        hidden: true,
        yAxisID: "lbpi",
      },

      {
        data:
          bowData.regression_curve !== null
            ? [bowData.regression_curve[0], pointThrough]
            : [],
        showLine: !showLineThroughFirstAndLast,
        hidden: !showLineThroughFirstAndLast,
        yAxisID: "lbs",
        label: "HIDE",
      },
    ],
  };

  const options = {
    scales: {
      x: {
        display: true,
        border: { width: 1.5, color: theme.palette.text.primary },
        min: bowData.df_data[0][0] - 1,
        max: bowData.df_data[bowData.df_data.length - 1][0] + 1,
        type: "linear" as const,
        title: {
          text: "Draw length in inches from the belly",
          display: true,
        },
      },
      lbs: {
        display: true,
        border: { width: 1.5, color: theme.palette.text.primary },
        position: "left" as const,
        title: {
          text: "Draw weight (lbs)",
          display: true,
        },
      },
      lbpi: {
        display: true,
        border: { width: 1.5, color: theme.palette.text.primary },
        grid: { drawOnChartArea: false },
        position: "right" as const,
        title: {
          text: showLbpiAsPercentage
            ? "Change in draw weight per inch (%)"
            : "Change in draw weight per inch (lbpi)",
          display: screenIsMinWidth,
        },
      },
    },
    plugins: {
      autocolors: false,
      title: {
        display: screenIsMinWidth,
        text: "Click on the graph labels to turn lines on or off",
      },
      responsive: true,
      annotation: {
        annotations: [
          {
            display: showLineAt27,
            type: "line" as const,
            mode: "vertical" as const,
            scaleID: "x",
            value: showLineAt,
            borderColor: "rgb(255, 99, 132)",
            borderWidth: 2,
            label: {
              display: true,
              position: "end" as const,
              content: `${showLineAt}" from the belly`,
            },
          },
        ],
      },
      legend: {
        display: screenIsMinWidth,
        labels: {
          filter(item: LegendItem) {
            return item.text !== "HIDE";
          },
        },
      },
      tooltip: {
        callbacks: {
          label(context: any) {
            const returnVal = [
              `${context.dataset.label}: ${context.formattedValue}`,
            ];
            if (
              context.dataset.label === "Regression DF Curve" &&
              regressionCoeffs !== undefined
            ) {
              const formattedWithLbs = `(${
                context.parsed.x
              }in, ${context.parsed.y.toFixed(2)}lbs)`;
              returnVal[0] = `${context.dataset.label}: ${formattedWithLbs}`;
              const calculatedEnergy = calcEnergyAtPoint(
                regressionCoeffs,
                context.dataset.data[0][0],
                context.parsed.x
              );
              returnVal.push(
                `Calculated energy: ${calculatedEnergy.toFixed(2)}J`
              );
            } else {
              const suffix = showLbpiAsPercentage ? "%" : "lbpi";
              returnVal[0] = `${context.dataset.label}: (${
                context.parsed.x
              }in, ${context.parsed.y.toFixed(2)}${suffix})`;
            }

            return returnVal;
          },
        },
      },
    },
    watermark: WATERMARK_SETTINGS,
  };

  const handleDrawLineThroughX = (val: number) => {
    if (allowableXValues.includes(val)) {
      const xIndice = allowableXValues.indexOf(val);
      setPointThrough(bowData.regression_curve[xIndice]);
      setShowLineThrough(val);
    }
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (!showLineThroughFirstAndLast) {
      setShowLineThroughFirstAndLast(true);
    }
    if (!Array.isArray(newValue)) {
      handleDrawLineThroughX(newValue);
    }
  };

  const handleVerticalSlider = (event: Event, newValue: number | number[]) => {
    if (!showLineAt27) {
      setShowLineAt27(true);
    }
    if (!Array.isArray(newValue)) {
      setShowLineAt(newValue);
    }
  };

  return (
    <div>
      <Stack>
        <Scatter data={data} options={options} />

        <FormGroup>
          {regressionCoeffs ? (
            <Tooltip
              arrow
              title="Calculate the bow's draw force. Defaults to df from belly if grip depth not available."
            >
              <FormControl>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Typography> The bow&apos;s draw force at </Typography>
                  </Grid>
                  <Grid item>
                    <OutlinedInput
                      inputMode="numeric"
                      type="number"
                      id="calc-dfc"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value === "") {
                          setDl(0);
                          return;
                        }
                        const userDl = parseFloat(e.target.value);
                        if (
                          !Number.isNaN(userDl) &&
                          userDl >= 0 &&
                          userDl < 100
                        ) {
                          setDl(userDl);
                        }
                      }}
                      size="small"
                      endAdornment={
                        <InputAdornment position="end"> inches</InputAdornment>
                      }
                      value={dl}
                      margin="dense"
                      sx={{
                        width: "20ch",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography>
                      from the{" "}
                      {gripDepth !== undefined && gripDepth > 1
                        ? "back"
                        : "belly"}{" "}
                      is {dfAt.toFixed(2)}
                      lbs
                    </Typography>
                  </Grid>
                </Grid>
              </FormControl>
            </Tooltip>
          ) : null}
          <FormControl>
            <Grid container>
              <Grid item>
                <Tooltip
                  arrow
                  title="Display a vertical line at the desired value. Useful for figuring out poundage at your draw length"
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showLineAt27}
                        onChange={() => {
                          return setShowLineAt27(!showLineAt27);
                        }}
                      />
                    }
                    label="Show vertical line at"
                  />
                </Tooltip>
              </Grid>
              <Grid item xs>
                <Slider
                  size="small"
                  value={showLineAt}
                  valueLabelDisplay="on"
                  valueLabelFormat={x => {
                    return `${x} inches from the belly`;
                  }}
                  step={1}
                  onChange={handleVerticalSlider}
                  min={allowableXValues[0]}
                  max={allowableXValues[allowableXValues.length - 1]}
                />
              </Grid>
            </Grid>
          </FormControl>

          <FormControl>
            <Grid container>
              <Grid item>
                <Tooltip
                  arrow
                  title="Draw a line through the first point and some other point. This lets you see what a theoretical longbow would be like at that point."
                >
                  <FormControlLabel
                    control={
                      <div>
                        <Switch
                          checked={showLineThroughFirstAndLast}
                          onChange={() => {
                            return setShowLineThroughFirstAndLast(
                              !showLineThroughFirstAndLast
                            );
                          }}
                        />
                      </div>
                    }
                    label="Show line through first point and point at x = "
                  />
                </Tooltip>
              </Grid>
              <Grid item xs>
                <Slider
                  size="small"
                  value={showLineThrough}
                  step={1}
                  valueLabelDisplay="on"
                  min={allowableXValues[0]}
                  max={allowableXValues[allowableXValues.length - 1]}
                  marks
                  onChange={handleSliderChange}
                />
              </Grid>
            </Grid>
          </FormControl>
          <FormControl>
            <Tooltip
              title="Show poundage increase at each x value as a percentage of the drawforce"
              arrow
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={showLbpiAsPercentage}
                    onChange={() => {
                      return setShowLbpiAsPercentage(!showLbpiAsPercentage);
                    }}
                  />
                }
                label="Show lbpi as percentage"
              />
            </Tooltip>
          </FormControl>
        </FormGroup>
      </Stack>
    </div>
  );
};

SingleBowGraph.defaultProps = {
  graphOptions: { displayDfData: false },
  regressionCoeffs: [],
  gripDepth: undefined,
};

export default SingleBowGraph;
