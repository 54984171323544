/* eslint-disable no-unused-vars */
import React, { useState, CSSProperties } from "react";
import { useCSVReader } from "react-papaparse";
import ShaftTable from "../ShaftTable/ShaftTable";
import SendToDbButton from "../SendToDbButton/SendToDbButton";
import { processShaftData } from "../../utils/utils";
import type { Shaft } from "../../types/apiTypes";
import { updateShafts } from "../../utils/apiCalls";

const styles = {
  csvReader: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
  } as CSSProperties,
  browseFile: {
    width: "20%",
  } as CSSProperties,
  acceptedFile: {
    border: "1px solid #ccc",
    height: 45,
    lineHeight: 2.5,
    paddingLeft: 10,
    width: "80%",
  } as CSSProperties,
  remove: {
    borderRadius: 0,
    padding: "0 20px",
  } as CSSProperties,
  progressBarBackgroundColor: {
    backgroundColor: "red",
  } as CSSProperties,
};

const AddShafts = () => {
  const [csvData, setCsvData] = useState<Shaft[]>();
  const { CSVReader } = useCSVReader();

  const handleFileUpload = (data: any) => {
    const processedShaftData = processShaftData(data.data) as Shaft[];
    setCsvData(processedShaftData);
  };

  const sendToDb = (data: Shaft[]) => {
    return updateShafts(data);
  };

  return (
    <div>
      <CSVReader
        onUploadAccepted={(results: any) => {
          handleFileUpload(results);
        }}
        onDrop={(results: any) => {
          handleFileUpload(results);
        }}
        config={{ header: true }}
      >
        {({
          getRootProps,
          acceptedFile,
          ProgressBar,
          getRemoveFileProps,
        }: any) => {
          return (
            <>
              <div style={styles.csvReader}>
                <button
                  type="button"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...getRootProps()}
                  style={styles.browseFile}
                >
                  Browse file
                </button>
                <div style={styles.acceptedFile}>
                  {acceptedFile && acceptedFile.name}
                </div>
                <button
                  type="button"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...getRemoveFileProps()}
                  style={styles.remove}
                >
                  Remove
                </button>
              </div>
              <ProgressBar style={styles.progressBarBackgroundColor} />
            </>
          );
        }}
      </CSVReader>
      <div>
        {csvData !== undefined && (
          <div>
            {" "}
            <ShaftTable data={csvData} />
            <SendToDbButton
              data={csvData}
              f={sendToDb}
              endpoint="api/shafts/new"
              successMessage="Added shafts!"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AddShafts;
