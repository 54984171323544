import React, { useEffect, useState } from "react";
import {
  Switch,
  Grid,
  Button,
  Box,
  Link,
  Stack,
  Modal,
  Typography,
  FormControlLabel,
} from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";
import TopBarProgress from "react-topbar-progress-indicator";
import { ToastContainer, toast } from "react-toastify";

import SearchBar from "../../components/SearchBar/SearchBar";
import SingleBowGraph from "../../components/SingleBowGraph/SingleBowGraph";
import { MIN_SCREEN_WIDTH } from "../../constants";
import type { OptionType } from "../../types/index.js";
import SingleBowFpsGraph from "../../components/SingleBowFpsGraph/SingleBowFpsGraph";
import { getSelectBowOptions, getSelectTagOptions } from "../../utils/utils";
import MultiSearchBar from "../../components/MultiSearchBar/MultiSearchBar";
import BowDataContainer from "../../components/BowDataContainer/BowDataContainer";
import ModelDisplay from "../../components/ModelDisplay/ModelDisplay";

import "react-toastify/dist/ReactToastify.css";
import {
  getFpsDataForSample,
  getSample,
  getSamplesForBowType,
  getTorsionForSample,
} from "../../utils/apiCalls";
import type {
  FpsDataPoint,
  SampleSummary,
  Tag,
  Sample,
  TorsionPoint,
} from "../../types/apiTypes";
import UpdateDisplay from "../../components/UpdateDisplay/UpdateDisplay";
import SingleBowTorsionGraph from "../../components/SingleBowTorsionGraph/SingleBowTorsionGraph";

interface SampleData {
  sample: Sample;
  fpsData: FpsDataPoint[];
  fpsRegressionData?: any;
}

TopBarProgress.config({
  barThickness: 4,
});

const Home: React.FC = () => {
  const screenIsMinWidth = useMediaQuery(`(min-width:${MIN_SCREEN_WIDTH}px)`);
  const [, setLoading] = useState(false);
  const [allBowOptions, setAllBowOptions] = useState<OptionType[]>([]);
  const [filteredBowOptions, setFilteredBowOptions] = useState<OptionType[]>(
    []
  );
  const [allTagOptions, setAllTagOptions] = useState<OptionType[]>([]);
  const [torsionData, setTorsionData] = useState<TorsionPoint[]>([]);
  const [availableSamples, setAvailableSamples] = useState<SampleSummary[]>([]);
  const [selectedSample, setSelectedSample] = useState<SampleData | null>(null);
  const [selectedButton, setSelectedButton] = useState<number | null>(null);
  const [tagSearch, setTagSearch] = useState(false);
  const [selectedBowTags, setSelectedBowTags] = useState<Tag[]>([]);
  const [selectedBow, setSelectedBow] = useState<OptionType | null>(null);
  const [showToast, setShowToast] = useState(false);
  const [displayImages, setDisplayImages] = useState(false);
  const [showUpdates, setShowUpdates] = useState(false);

  useEffect(() => {
    const fetcher = async () => {
      setLoading(true);
      const fetchedBows = await getSelectBowOptions();
      const fetchedTags = await getSelectTagOptions();
      setAllBowOptions(fetchedBows);
      setFilteredBowOptions(fetchedBows);
      setAllTagOptions(fetchedTags);
      setLoading(false);
    };
    fetcher();
  }, []);

  useEffect(() => {
    setDisplayImages(false);
    setSelectedSample(null);
  }, [selectedBow]);

  useEffect(() => {
    setSelectedBow(null);
    setSelectedSample(null);
  }, [tagSearch]);

  useEffect(() => {
    if (showToast) {
      toast.error(`Failed to fetch data! Please refresh or try again later!`);
    }
  }, [showToast]);

  const handleSelectBow = async (selectedOption: OptionType | null) => {
    setLoading(true);
    setSelectedSample(null);
    if (selectedOption) {
      const selectedOptionTags = selectedOption.extraProps.tags as number[];
      const filteredTags = allTagOptions
        .filter((tag: OptionType) => {
          return selectedOptionTags.indexOf(parseFloat(tag.value)) !== -1;
        })
        .map(tag => {
          return tag.extraProps;
        }); // Used for bowData

      setSelectedBowTags(filteredTags);
      setSelectedBow(selectedOption);
      setShowToast(false);
      try {
        const samplesForBowType = await getSamplesForBowType(
          parseFloat(selectedOption.value)
        );
        setAvailableSamples(
          samplesForBowType.sort((a: SampleSummary, b: SampleSummary) => {
            return a.sampleId - b.sampleId;
          })
        );
        setLoading(false);
      } catch (error) {
        setShowToast(true);
      }
    }
  };

  const handleSelectTag = (selectedOptions: readonly OptionType[] | null) => {
    const filterBowOptions = () => {
      return allBowOptions.filter(bow => {
        const tagIds = bow.extraProps.tags || [];
        return selectedOptions?.every(option => {
          return tagIds.includes(parseFloat(option.value));
        });
      });
    };

    if (selectedOptions !== null) {
      const newOptions = filterBowOptions();
      setFilteredBowOptions(newOptions);
    }
  };

  const handleSelectSample = async (sampleId: number) => {
    setLoading(true);
    setShowToast(false);
    try {
      const sample = (await getSample(sampleId)) as Sample;
      const fpsData = await getFpsDataForSample(sampleId);
      const torsionResponse = await getTorsionForSample(sampleId);

      setSelectedButton(sampleId);
      setSelectedSample({ sample, fpsData });
      setTorsionData(torsionResponse);
      setLoading(false);
    } catch (error) {
      setShowToast(true);
    }
  };

  const handleResetSearches = () => {
    setFilteredBowOptions([...allBowOptions]);
    setSelectedButton(null);
    setSelectedSample(null);
    setAvailableSamples([]);
    setDisplayImages(false);
    setSelectedBow(null);
    setSelectedBowTags([]);
  };

  return (
    <Stack>
      <Button
        variant="contained"
        sx={{ maxWidth: 200, mb: "1rem" }}
        onClick={() => {
          setShowUpdates(!showUpdates);
        }}
      >
        Show Recent Updates
      </Button>
      <Modal
        open={showUpdates}
        onClose={() => {
          setShowUpdates(false);
        }}
      >
        <UpdateDisplay
          setSelectedBow={handleSelectBow}
          setSelectedButton={handleSelectSample}
        />
      </Modal>
      <ToastContainer />
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item> Search by bow model </Grid>
        <Grid item>
          <Switch
            checked={tagSearch !== false}
            onChange={() => {
              handleResetSearches();
              return setTagSearch(!tagSearch);
            }}
          />
        </Grid>
        <Grid item> Search by tags </Grid>
      </Grid>

      {tagSearch === true && (
        <div>
          <MultiSearchBar
            name={
              allTagOptions.length > 0 ? "Select a tag" : "No tags available"
            }
            onChange={handleSelectTag}
            options={allTagOptions}
          />
        </div>
      )}
      <div>
        <SearchBar
          name={
            filteredBowOptions.length > 0 ? "Select a bow" : "No bows available"
          }
          onChange={handleSelectBow}
          options={filteredBowOptions}
          forcedValue={selectedBow}
        />

        {availableSamples.map((sample: SampleSummary) => {
          return (
            <Button
              key={sample.sampleId}
              variant={
                selectedButton === sample.sampleId ? "contained" : "outlined"
              }
              size="small"
              sx={{
                mt: 1,
                mx: 0.5,
                backgroundColor: theme => {
                  return selectedButton === sample.sampleId
                    ? theme.palette.primary.dark
                    : theme.palette.primary.light;
                },
                color: theme => {
                  return theme.palette.primary.contrastText;
                },
              }}
              onClick={() => {
                return handleSelectSample(sample.sampleId);
              }}
            >
              <div style={{ textAlign: "center", fontSize: "0.7rem" }}>
                <Typography variant="button" sx={{ m: 0, p: 0 }}>
                  {sample.submodel !== null ? `${sample.submodel} -` : ``}{" "}
                  {sample.nominalPoundage !== null
                    ? `${sample.nominalPoundage}`
                    : ``}
                </Typography>
                <br />
                <Typography
                  variant="caption"
                  fontSize="0.7rem"
                  sx={{ m: 0, p: 0 }}
                >{`Sample #${sample.sampleId}`}</Typography>
              </div>
            </Button>
          );
        })}
      </div>

      <Grid container>
        <Grid item xs={12}>
          {selectedSample !== null && selectedBow !== null && (
            <Box justifyContent="center" display="flex">
              <Link
                display="block"
                variant="h4"
                color="primary"
                href={selectedBow?.extraProps.bow_link}
              >
                {selectedBow.label} - {`#${selectedButton}`}
              </Link>
            </Box>
          )}
        </Grid>
        <Grid item xs={screenIsMinWidth ? 3 : 12}>
          {selectedSample !== null && (
            <BowDataContainer
              data={selectedSample.sample}
              tags={selectedBowTags}
              direction="column"
            />
          )}
        </Grid>
        <Grid item xs={screenIsMinWidth ? 9 : 12}>
          {selectedSample?.sample ? (
            <SingleBowGraph
              bowData={{
                regression_curve: selectedSample.sample.regressionCurve,
                regression_derivative_values:
                  selectedSample.sample.regressionDerivativeValues,
                df_data: selectedSample.sample.dfData,
                central_differences: selectedSample.sample.centralDifferences,
              }}
              regressionCoeffs={selectedSample.sample.coeffs}
              gripDepth={selectedSample.sample.gripDepth}
            />
          ) : null}
          {selectedSample?.fpsData && selectedSample.fpsData.length > 0 ? (
            <SingleBowFpsGraph fpsData={selectedSample?.fpsData} />
          ) : null}
          {selectedSample !== null && torsionData.length > 0 ? (
            <SingleBowTorsionGraph
              torsionData={torsionData}
              nominalPoundage={
                selectedSample!.sample.regressionCurve.find(
                  (point: number[]) => {
                    return point[0] === 27;
                  }
                ) !== undefined
                  ? selectedSample!.sample.regressionCurve.find(
                      (point: number[]) => {
                        return point[0] === 27;
                      }
                    )![1]
                  : 9999
              }
            />
          ) : null}
        </Grid>
      </Grid>
      <div>
        {selectedBow !== null && (
          <FormControlLabel
            control={
              <Switch
                checked={displayImages}
                onChange={() => {
                  setDisplayImages(!displayImages);
                }}
              />
            }
            label="Display images?"
          />
        )}

        {displayImages === true && selectedBow !== null && (
          <ModelDisplay
            modelId={parseFloat(selectedBow.value)}
            sampleId={
              selectedSample !== null
                ? selectedSample.sample.sampleId
                : undefined
            }
          />
        )}
      </div>
    </Stack>
  );
};

export default Home;
